import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
// Removed HackingConsole import
import styles from './LiberationConsoleSection.module.css'; // Import the CSS Module
import TerminalPrompt from '../Terminal/TerminalPrompt'; // Assuming TerminalPrompt exists

// Helper for simulating typing delay
const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Component for the interactive Liberation Console section
const LiberationConsoleSection = ({ updateTerminalContent }) => {
  const [stage, setStage] = useState('initial'); // initial, auditRunning, auditResults, scenario1Question, ...
  const [outputLines, setOutputLines] = useState([]);
  const [revealedBenefits, setRevealedBenefits] = useState(new Set());
  const consoleEndRef = useRef(null); // Ref to auto-scroll

  const prompt = "liberation@digital-sovereignty:~$";

  // Function to add a line to the output
  const addLine = (text, type = 'response', command = '') => {
    setOutputLines(prev => [...prev, { text, type, command, id: Date.now() + Math.random() }]);
  };

  // Function to simulate typing a command
  const typeCommand = async (command) => {
    addLine(command, 'command-typing', command); // Add placeholder for typing
    await wait(500); // Short pause before typing starts
    
    let typedCommand = '';
    for (let i = 0; i < command.length; i++) {
      typedCommand += command[i];
      setOutputLines(prev => {
        const newLines = [...prev];
        newLines[newLines.length - 1] = { ...newLines[newLines.length - 1], text: typedCommand };
        return newLines;
      });
      await wait(50); // Typing speed
    }
     // Replace typing line with final command line
     setOutputLines(prev => {
        const newLines = [...prev];
        newLines[newLines.length - 1] = { ...newLines[newLines.length - 1], type: 'command', text: command };
        return newLines;
      });
  };

  // Initial effect to start the audit
  useEffect(() => {
    if (stage === 'initial') {
      const init = async () => {
        addLine(''); // Start with prompt
        await wait(1000);
        setStage('auditRunning');
        await typeCommand('run liberation_audit.sh');
        await wait(500);
        addLine('Scanning dependencies...');
        await wait(1000);
        addLine('[!] CLOUD_INFRA: Vendor Lock-in Detected (AWS/Azure/GCP)', 'error');
        await wait(700);
        addLine('[!] DATA_STORAGE: External Control Detected (Drive/Dropbox)', 'error');
        await wait(700);
        addLine('[!] COMMS_SUITE: Third-Party Surveillance Risk (Slack/Teams)', 'error');
        await wait(700);
        addLine('[!] ANALYSIS: Sovereignty Index Critical.', 'warning');
        await wait(1000);
        setStage('scenario1Question'); // Move to the first question
      };
      init();
    }
  }, [stage]);

  // Auto-scroll effect - REMOVED
  // useEffect(() => {
  //   const consoleElement = consoleEndRef.current?.parentElement; // Get the container
  //   if (consoleElement && consoleElement.scrollHeight > consoleElement.clientHeight) {
  //      consoleEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [outputLines]);

  // --- Handlers for Scenario Choices ---
  const handleScenarioChoice = async (scenarioNum, choice, correctChoice, command, successMsg, errorMsg, benefitKey, asciiArt) => {
    setStage(`scenario${scenarioNum}Processing`); // Disable buttons

    const chosenOptionText = {
      1: { A: 'absorb_cost.dll', B: 'migrate_painfully.exe', C: 'activate_sovereign_cloud.sh' },
      2: { A: 'ignore_alert.tmp', B: 'request_data_deletion.bat', C: 'deploy_zero_knowledge.bin' },
      3: { A: 'accept_feature_loss.cfg', B: 'find_alternative_service.lnk', C: 'initiate_fork_and_develop.py' }
    }[scenarioNum][choice];

    await typeCommand(chosenOptionText); // Simulate choosing the option
    await wait(500);

    if (choice === correctChoice) {
      addLine(`> Executing ${command}...`, 'info');
      await wait(1000);
      if (asciiArt) {
         // Add ASCII art line by line for effect
         const artLines = asciiArt.split('\n');
         for (const artLine of artLines) {
            addLine(artLine, 'visual');
            await wait(50);
         }
      }
      await wait(500);
      addLine(`// RESULT: ${successMsg}`, 'success');
      setRevealedBenefits(prev => new Set(prev).add(benefitKey));
    } else {
      addLine(`> ERROR: ${errorMsg}`, 'error');
    }
    await wait(1500);

    // Transition to next stage or final results
    if (scenarioNum < 3) {
      setStage(`scenario${scenarioNum + 1}Question`);
    } else {
      setStage('finalResults');
    }
  };

  // --- ASCII Art Placeholders ---
  const cloudArt = `
      .--""--.
     :        :
    :  ------  :
   :  / o  o \\  :
  :  \\  ^^  /  :
 :    ------    :
:______________ :
 \\____________\\/`; // Simple cloud with slash (needs refinement)

 const shieldArt = `
   _______
  /       \\
 |  -----  |
 | |  ✓  | |
 |  -----  |
  \\_______/
`;

 const codeArt = `
  _________
 | <> {}  |
 | //...   |
 |_________|
`;

  // --- Freedom Matrix Data ---
  const freedomMatrix = {
    fixedCosts: "[✓] Self-hosted systems with fixed costs",
    ownership: "[✓] Complete infrastructure ownership",
    openSource: "[✓] Open-source software foundation",
    updateControl: "[✓] Self-determined update schedule",
    // Add more keys corresponding to benefitKey arguments
    dataSovereignty: "[✓] Data Sovereignty Secured",
  };


  return (
    <Container fluid className={styles.hackingSection}>
      <Container>
        <div className={`${styles.sectionTitle} mb-5`}>
          <h2 className={`gradient-text ${styles.gradientText} ${styles.neonGreen}`}>
            Liberation <span className={`accent-text ${styles.accentText}`}>Protocol</span>
          </h2>
          <div className={styles.titleUnderline}></div>
        </div>

        <Row className="justify-content-center">
          <Col md={10}>
            {/* New Console Display Area */}
            <div className={styles.consoleContainer}>
              {outputLines.map((line, index) => (
                <div key={line.id || index} className={`${styles.consoleLine} ${styles[line.type]}`}>
                  {line.type === 'command' && <span>{prompt} </span>}
                  {line.type === 'command-typing' && <span>{prompt} {line.text}<span className={styles.cursor}></span></span>}
                  {line.type !== 'command-typing' && line.type !== 'command' && <span>{line.text}</span>}
                   {/* Render final command without cursor once typing is done */}
                  {line.type === 'command' && index === outputLines.length -1 && stage !== 'auditRunning' && <span>{line.text}</span>}

                </div>
              ))}

              {/* --- Render Questions/Options based on stage --- */}
              {stage === 'scenario1Question' && (
                <div className={styles.scenario}>
                  <div className={styles.question}>// Cloud provider hikes prices 30%. Your response protocol?</div>
                  <div className={styles.options}>
                    <button onClick={() => handleScenarioChoice(1, 'A', 'C', '', '', 'Dependency Reinforced. Sovereignty Compromised.', '', '')} className={styles.optionButton}>[A] absorb_cost.dll</button>
                    <button onClick={() => handleScenarioChoice(1, 'B', 'C', '', '', 'Dependency Reinforced. Sovereignty Compromised.', '', '')} className={styles.optionButton}>[B] migrate_painfully.exe</button>
                    <button onClick={() => handleScenarioChoice(1, 'C', 'C', 'activate_sovereign_cloud.sh', 'Infrastructure Costs Stabilized. Control Established.', '', 'fixedCosts', cloudArt)} className={styles.optionButton}>[C] activate_sovereign_cloud.sh</button>
                  </div>
                </div>
              )}
              {stage === 'scenario2Question' && (
                 <div className={styles.scenario}>
                  <div className={styles.question}>// Audit reveals unexpected data egress to third-party analytics. Your response protocol?</div>
                  <div className={styles.options}>
                    <button onClick={() => handleScenarioChoice(2, 'A', 'C', '', '', 'Data Leak Persists. Privacy Breach Imminent.', '', '')} className={styles.optionButton}>[A] ignore_alert.tmp</button>
                    <button onClick={() => handleScenarioChoice(2, 'B', 'C', '', '', 'Data Leak Persists. Privacy Breach Imminent.', '', '')} className={styles.optionButton}>[B] request_data_deletion.bat</button>
                    <button onClick={() => handleScenarioChoice(2, 'C', 'C', 'deploy_zero_knowledge.bin', 'Data Sovereignty Secured. External Access Denied.', '', 'dataSovereignty', shieldArt)} className={styles.optionButton}>[C] deploy_zero_knowledge.bin</button>
                  </div>
                </div>
              )}
               {stage === 'scenario3Question' && (
                 <div className={styles.scenario}>
                  <div className={styles.question}>// Critical SaaS tool sunsets core feature without warning. Your response protocol?</div>
                  <div className={styles.options}>
                    <button onClick={() => handleScenarioChoice(3, 'A', 'C', '', '', 'Operational Capability Reduced. Subject to Vendor Whims.', '', '')} className={styles.optionButton}>[A] accept_feature_loss.cfg</button>
                    <button onClick={() => handleScenarioChoice(3, 'B', 'C', '', '', 'Operational Capability Reduced. Subject to Vendor Whims.', '', '')} className={styles.optionButton}>[B] find_alternative_service.lnk</button>
                    <button onClick={() => handleScenarioChoice(3, 'C', 'C', 'initiate_fork_and_develop.py', 'Development Independence Achieved. Update Schedule Controlled.', '', 'openSource', codeArt)} className={styles.optionButton}>[C] initiate_fork_and_develop.py</button>
                  </div>
                </div>
              )}

              {/* --- Final Results --- */}
              {stage === 'finalResults' && (
                <div className={styles.finalResults}>
                   <div className={styles.matrixTitle}>// FREEDOM MATRIX //</div>
                   {Object.entries(freedomMatrix).map(([key, text]) => (
                     <div key={key} className={revealedBenefits.has(key) ? styles.benefitRevealed : styles.benefitHidden}>
                       {revealedBenefits.has(key) ? text : text.replace('✓', '✗').replace('Self-hosted', 'Vendor-hosted').replace('Complete', 'Limited').replace('Open-source', 'Proprietary').replace('Self-determined', 'Vendor-controlled')}
                     </div>
                   ))}
                   <div className={styles.finalIndex}>
                     // PROTOCOL COMPLETE. Sovereignty Index: {Math.round((revealedBenefits.size / Object.keys(freedomMatrix).length) * 100)}%. Liberation is a continuous process.
                   </div>
                </div>
              )}


              {/* Always show prompt at the end if interaction is not complete */}
              {stage !== 'initial' && stage !== 'auditRunning' && !stage.includes('final') && !stage.includes('Processing') && (
                 <div className={`${styles.consoleLine} ${styles.promptLine}`}>
                    <span>{prompt} </span><span className={styles.cursor}></span>
                 </div>
              )}

              {/* <div ref={consoleEndRef} /> */} {/* Scroll target no longer needed */}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default LiberationConsoleSection;
