// main-site/bio/src/data/arsenalItems/gnupg.js
import React from 'react';
import { SiGnuprivacyguard } from "react-icons/si";
import { FaBook, FaExternalLinkAlt, FaEnvelope } from "react-icons/fa"; // Added icons

const gnupg = {
  id: "gnupg",
  name: "GnuPG",
  icon: <SiGnuprivacyguard />,
  category: "security",
  description: "Complete implementation of the OpenPGP standard for end-to-end encrypted communication and file protection",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Email encryption",
    "Digital signatures",
    "Secure file transfer and verification"
  ],
  // Additional details for expanded view
  detailedDescription: "GnuPG (GNU Privacy Guard) is a free software implementation of the OpenPGP standard (RFC 4880). It allows users to encrypt and sign data and communications, featuring a versatile key management system as well as access modules for all kinds of public key directories. GnuPG is a command-line tool with features for easy integration with other applications.",
  advantages: [
    "Strong, proven public-key cryptography for encryption and digital signatures",
    "Free and open-source, ensuring transparency and auditability",
    "Cross-platform availability (Linux, macOS, Windows)",
    "Supports various encryption and signing algorithms",
    "Integrates with many email clients and other applications"
  ],
  alternatives: [
    { name: "S/MIME (Certificate-based email encryption)", icon: <FaEnvelope />, sovereignty: 3 }, // Relies on CAs
    { name: "Proprietary PGP implementations (e.g., Symantec)", icon: <FaEnvelope />, sovereignty: 2 }
  ],
  resources: [
    {
      title: "GnuPG Website",
      url: "https://gnupg.org/",
      icon: <FaExternalLinkAlt />
    },
    {
      title: "GnuPG Handbook",
      url: "https://www.gnupg.org/gph/en/manual.html",
      icon: <FaBook />
    }
  ],
  installationSteps: [
    "Install GnuPG using your system's package manager (apt, brew, pacman, etc.) or download from gnupg.org",
    "Verify installation with 'gpg --version'",
    "Generate a new key pair: 'gpg --full-generate-key'",
    "Learn basic commands: 'gpg --encrypt', 'gpg --decrypt', 'gpg --sign', 'gpg --verify'",
    "Manage keys: 'gpg --list-keys', 'gpg --import', 'gpg --export', 'gpg --send-keys', 'gpg --recv-keys'"
  ]
};

export default gnupg;
