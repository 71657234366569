// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright, .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;EAC/B,qBAAqB;AACvB","sourcesContent":["@import './variables.css';\n\n.footer {\n  background-color: rgb(10, 4, 22);\n  bottom: 0 !important;\n  padding-top: 10px !important;\n  padding-bottom: 8px !important;\n}\n\n.footer-copywright {\n  text-align: center !important;\n}\n\n.footer-body {\n  z-index: 1;\n  text-align: center !important;\n}\n\n@media (max-width: 767px) {\n  .footer-copywright, .footer-body {\n    text-align: center !important;\n  }\n}\n\n.footer h3 {\n  font-size: 1em;\n  color: white !important;\n  margin-top: 0.5em !important;\n  margin-bottom: 0.5em !important;\n}\n\n.footer-icons {\n  margin-top: 0.5em !important;\n  margin-bottom: 0.5em !important;\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
