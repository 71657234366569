// Data for the ArsenalDisplay component

// Import necessary icons for categories
import React from 'react'; // Import React because JSX (icons) is used
import {
  FaServer, FaShieldAlt, FaLock, FaCode, FaNetworkWired, FaDatabase
} from "react-icons/fa";

// Import individual arsenal items
import proxmox from './arsenalItems/proxmox';
import nginx from './arsenalItems/nginx';
import postgres from './arsenalItems/postgres';
import mongodb from './arsenalItems/mongodb';
import wireguard from './arsenalItems/wireguard';
import pfsense from './arsenalItems/pfsense';
import grafana from './arsenalItems/grafana';
import prometheus from './arsenalItems/prometheus';
import docker from './arsenalItems/docker';
import openssl from './arsenalItems/openssl';
import matrix from './arsenalItems/matrix';
import nextcloud from './arsenalItems/nextcloud';
import gnupg from './arsenalItems/gnupg';
import linux from './arsenalItems/linux';
import markdown from './arsenalItems/markdown';

export const categories = [
  { id: "all", name: "All Arsenal", icon: <FaShieldAlt /> },
  { id: "infrastructure", name: "Infrastructure", icon: <FaServer /> },
  { id: "security", name: "Security", icon: <FaLock /> },
  { id: "network", name: "Network", icon: <FaNetworkWired /> },
  { id: "storage", name: "Storage", icon: <FaDatabase /> },
  { id: "development", name: "Development", icon: <FaCode /> }
];

// Reconstruct the arsenalItems array from individual imports
export const arsenalItems = [
  proxmox,
  nginx,
  postgres,
  mongodb,
  wireguard,
  pfsense,
  grafana,
  prometheus,
  docker,
  openssl,
  matrix,
  nextcloud,
  gnupg,
  linux,
  markdown
];

// Note: Icons for individual items are now handled within their respective files.
// The main arsenalContent.js file only needs icons for the categories.
