// main-site/bio/src/data/arsenalItems/openssl.js
import React from 'react';
import { SiOpenssl } from "react-icons/si";
import { FaBook, FaGithub, FaLock } from "react-icons/fa"; // Added icons

const openssl = {
  id: "openssl",
  name: "OpenSSL",
  icon: <SiOpenssl />,
  category: "security",
  description: "Robust, full-featured open-source toolkit for the Transport Layer Security (TLS) protocols",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Certificate management and PKI",
    "Encryption key generation",
    "TLS implementation for services"
  ],
  // Additional details for expanded view
  detailedDescription: "OpenSSL is a software library for applications that secure communications over computer networks against eavesdropping or need to identify the party at the other end. It is widely used by Internet servers, including the majority of HTTPS websites. It contains an open-source implementation of the SSL and TLS protocols, as well as a general-purpose cryptography library.",
  advantages: [
    "De facto standard for TLS/SSL implementation in the open-source world",
    "Provides a wide range of cryptographic functions (symmetric, asymmetric, hashing)",
    "Supports generation and management of X.509 certificates and keys",
    "Cross-platform compatibility (Linux, macOS, Windows, BSD, etc.)",
    "Actively maintained with regular security updates"
  ],
  alternatives: [
    { name: "LibreSSL (OpenBSD fork)", icon: <FaLock />, sovereignty: 5 },
    { name: "GnuTLS", icon: <FaLock />, sovereignty: 5 },
    { name: "BoringSSL (Google fork)", icon: <FaLock />, sovereignty: 4 }
  ],
  resources: [
    {
      title: "Official Website & Documentation",
      url: "https://www.openssl.org/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/openssl/openssl",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "OpenSSL is pre-installed on most Linux and macOS systems",
    "Verify installation with 'openssl version'",
    "For Windows, download pre-compiled binaries or compile from source",
    "Use the 'openssl' command-line tool for various operations (e.g., 'openssl genrsa', 'openssl req')",
    "Consult documentation for specific commands and library usage"
  ]
};

export default openssl;
