// main-site/bio/src/data/arsenalItems/docker.js
import React from 'react';
import { FaDocker, FaBook, FaGithub, FaCube } from "react-icons/fa"; // Added icons

const docker = {
  id: "docker",
  name: "Docker",
  icon: <FaDocker />,
  category: "development",
  description: "Containerization platform enabling consistent application deployment across environments",
  sovereignty: 4,
  expertise: 5,
  useCases: [
    "Application containerization",
    "Microservices architecture",
    "Development environment standardization"
  ],
  // Additional details for expanded view
  detailedDescription: "Docker is a platform that enables developers to build, ship, and run applications inside containers—lightweight, portable, self-sufficient environments. It packages software into standardized units for development, shipment, and deployment, ensuring consistency across different environments.",
  advantages: [
    "Consistent environments from development to production",
    "Resource isolation and efficiency compared to traditional VMs",
    "Rapid application deployment and scaling",
    "Large ecosystem (Docker Hub) for sharing and using container images",
    "Facilitates microservices architecture and CI/CD pipelines"
  ],
  alternatives: [
    { name: "Podman", icon: <FaCube />, sovereignty: 5 },
    { name: "LXC/LXD", icon: <FaCube />, sovereignty: 5 },
    { name: "Kubernetes (Orchestration)", icon: <FaCube />, sovereignty: 5 } // Often used with Docker
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://docs.docker.com/",
      icon: <FaBook />
    },
    {
      title: "Docker Hub",
      url: "https://hub.docker.com/",
      icon: <FaGithub /> // Using GitHub icon as a proxy for registry
    }
  ],
  installationSteps: [
    "Follow the official Docker installation guide for your OS (Linux, macOS, Windows)",
    "Add your user to the 'docker' group (Linux) to run commands without sudo",
    "Verify installation by running 'docker run hello-world'",
    "Learn basic commands: docker build, docker run, docker ps, docker images",
    "Explore Docker Compose for multi-container applications"
  ]
};

export default docker;
