import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={12}
            style={{
              justifyContent: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
              background: "rgba(10, 10, 46, 0.8)",
              borderRadius: "25px",
              backdropFilter: "blur(10px)",
              border: "2px solid rgba(0, 255, 255, 0.1)",
              position: "relative",
              overflow: "hidden",
              marginBottom: "20px",
              zIndex: 1
            }}
          >
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "linear-gradient(45deg, rgba(0, 255, 255, 0.1) 0%, transparent 100%)",
              pointerEvents: "none",
              zIndex: -1
            }} />
            <h1 style={{ 
              fontSize: "2.5em", 
              paddingBottom: "20px",
              background: "linear-gradient(90deg, #0ff, #f0f)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
              textShadow: "0 0 10px rgba(0, 255, 255, 0.5)",
              textAlign: "center"
            }}>
              Know Who <strong>I AM</strong>
            </h1>
            <Aboutcard />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div style={{
              background: "rgba(10, 10, 46, 0.8)",
              borderRadius: "25px",
              padding: "20px",
              backdropFilter: "blur(10px)",
              border: "2px solid rgba(0, 255, 255, 0.1)",
              position: "relative",
              overflow: "hidden",
              marginBottom: "20px",
              height: "100%"
            }}>
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 255, 255, 0.03) 10px, rgba(0, 255, 255, 0.03) 20px)",
                pointerEvents: "none"
              }} />
              <h1 className="project-heading" style={{ 
                fontSize: "2em",
                textAlign: "center",
                marginBottom: "20px"
              }}>
                Professional <strong className="purple">Skillset </strong>
              </h1>
              <Techstack />
            </div>
          </Col>

          <Col md={6}>
            <div style={{
              background: "rgba(10, 10, 46, 0.8)",
              borderRadius: "25px",
              padding: "20px",
              backdropFilter: "blur(10px)",
              border: "2px solid rgba(0, 255, 255, 0.1)",
              position: "relative",
              overflow: "hidden",
              marginBottom: "20px",
              height: "100%"
            }}>
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(135deg, rgba(0, 255, 255, 0.05) 0%, transparent 100%)",
                pointerEvents: "none"
              }} />
              <h1 className="project-heading" style={{
                fontSize: "2em",
                textAlign: "center",
                marginBottom: "20px"
              }}>
                <strong className="purple">Tools</strong> I use
              </h1>
              <Toolstack />
            </div>
          </Col>
        </Row>

        <div style={{
          background: "rgba(10, 10, 46, 0.8)",
          borderRadius: "25px",
          padding: "20px",
          backdropFilter: "blur(10px)",
          border: "2px solid rgba(0, 255, 255, 0.1)",
          position: "relative",
          overflow: "hidden",
          marginBottom: "20px"
        }}>
          <Github />
        </div>
      </Container>
    </Container>
  );
}

export default About;
