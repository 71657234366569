import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Preloader from "./components/Pre";
import Navbar from "./components/Navbar";
import HomeTerminal from "./components/Home/HomeTerminal";
import Manifesto from "./components/Manifesto/Manifesto";
import Liberation from "./components/Liberation/Liberation";
import ArsenalDisplay from "./components/Arsenal/ArsenalDisplay";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
// Removed MatrixRain import
import "./style.css";
import "./App.css";
import "./styles/terminal.css";
import "./styles/literary.css";
import "./styles/resistance.css";
import "./styles/cyberpunk-effects.css";
import "./styles/terminal-section.css";
import "./components/navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App digital-resistance" id={load ? "no-scroll" : "scroll"}>
        {/* Removed MatrixRain component */}
        {/* Removed site-wide scanlines - we'll place them directly in components */}
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeTerminal />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/liberation" element={<Liberation />} />
          <Route path="/arsenal" element={<ArsenalDisplay />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
