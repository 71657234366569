import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ResumeHeader from "./ResumeHeader";
import ProfessionalSummary from "./ProfessionalSummary";
import TechnicalArsenal from "./TechnicalArsenal";
import Education from "./Education";
import ProfessionalJourney from "./ProfessionalJourney";
import InnovationPortfolio from "./InnovationPortfolio";
import "../../styles/resume.css";

function ResumeNew() {
  return (
    <div className="resume-section">
      <Particle />
      <Container>
        <Row>
          <Col md={12}>
            <ResumeHeader />
          </Col>
        </Row>

        <Row>
          <Col md={12} className="resume-container">
            <ProfessionalSummary />
            <TechnicalArsenal />
            <Education />
            <ProfessionalJourney />
            <InnovationPortfolio />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
