import React from "react";
import { FaBriefcase } from "react-icons/fa";

function ProfessionalJourney() {
  return (
    <div className="section">
      <h2 className="section-title">
        <FaBriefcase /> Professional Journey
      </h2>
      
      <div className="experience-item">
        <h3 className="experience-title">Enterprise Technology Consultant</h3>
        <h4 className="experience-company">Independent</h4>
        <p className="experience-duration">January 2012 - Present</p>
        <ul>
          <li>Architected comprehensive enterprise stacks integrating cutting-edge AI technologies</li>
          <li>Led Office365 administration for major museum (150+ users) in Charlotte NC</li>
          <li>Engineered 600+ custom automation scripts for enterprise-wide system monitoring</li>
          <li>Orchestrated Windows 10 updates across 2000+ machines with zero downtime</li>
          <li>Implemented military-grade security solutions for healthcare providers</li>
          <li>Delivered high-impact solutions in tech hubs: San Francisco, NYC, LA, Miami</li>
        </ul>
      </div>

      <div className="experience-item">
        <h3 className="experience-title">SR. NOC Tech / Network Engineer</h3>
        <h4 className="experience-company">G.W. List</h4>
        <p className="experience-duration">November 2019 - March 2020</p>
        <ul>
          <li>Spearheaded cybersecurity architecture for mission-critical aerospace systems</li>
          <li>Pioneered virtualization of legacy manufacturing infrastructure</li>
          <li>Designed and implemented enterprise-wide GPO security protocols</li>
          <li>Managed complex diagnostics across multi-platform environments</li>
        </ul>
      </div>

      <div className="experience-item">
        <h3 className="experience-title">System Administrator</h3>
        <h4 className="experience-company">TechGuru</h4>
        <p className="experience-duration">September 2017 - March 2019</p>
        <ul>
          <li>Led rapid response team for critical cybersecurity incidents</li>
          <li>Orchestrated IT operations for 800+ MSP and hospitality clients</li>
          <li>Executed full recovery of 150 compromised servers post-security breach</li>
          <li>Reconstructed core infrastructure components in crisis scenarios</li>
        </ul>
      </div>
    </div>
  );
}

export default ProfessionalJourney;
