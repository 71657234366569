import React from 'react';
import styles from './HeroBanner.module.css'; // Import the CSS Module

// Component specifically for the main hero banner content
const HeroBanner = () => {
  return (
    // Use CSS module class names
    <div className={styles.heroCenterContent}>
      {/* Scanning line effect */}
      <div className={styles.scanningLine}></div>
      
      {/* Apply global class for core glitch + module class for specific styles */}
      <h1 className={`mega-glitch-title ${styles.megaGlitchTitle}`} data-text="REJECT THE SUBSCRIPTION PARADIGM">
        {/* Apply global class for core glitch + module class for specific styles */}
        <span className={`glitch-text ${styles.glitchTextHighlight}`} data-text="REJECT">REJECT</span> THE <span className={`glitch-text ${styles.glitchTextHighlight}`} data-text="SUBSCRIPTION">SUBSCRIPTION</span> <span className={styles.paradigmItalic}>PARADIGM</span>
        <div className={styles.subTitleTopRight}>
          First Law of Digital Liberation
        </div>
        <div className={styles.subTitleBottomLeft}>
          FOUNDATION THEOREM 3.927.4
        </div>
      </h1>
      
      <div className={styles.leftAlignedContent}>
        <div className={styles.pulseCircle}></div>
        
        <div className={styles.identityQuestion}>CLASSIFIED SUBJECT PROFILE</div>
        
        <div className={styles.literaryHeadingContainer}>
           {/* Apply global class for core glitch + module class for specific styles */}
          <h2 className={`glitch-text ${styles.literaryHeading}`} data-text="ARCHITECT OF DIGITAL LIBERATION">
             {/* Use spans for cleaner line break */}
             <span className={styles.literaryHeadingText}>
               ARCHITECT OF<br />DIGITAL LIBERATION
             </span>
             {/* Keep data-text on the parent h2 for glitch effect */}
          </h2>
           {/* Remove redundant desktop/mobile divs if styling allows */}
        </div>
      </div>
      
      {/* Visual indicator for the Z pattern */}
      <div className={styles.zPatternIndicator}></div>
    </div>
  );
};

export default HeroBanner;
