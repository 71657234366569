import React, { useState, useEffect } from "react";
// Added Col import
import { Container, Row, Col } from "react-bootstrap";
import { sovereigntyStatements } from "../../data/homeContent";
import HackerManifestoModal from "./HackerManifestoModal";
import HeroBanner from "./HeroBanner";
import TerminalDisplay from "./TerminalDisplay"; 
import IdentityCard from "./IdentityCard"; 
import ManifestoPreview from "./ManifestoPreview"; 
import LiberationPathways from "./LiberationPathways"; 
import LiberationConsoleSection from "./LiberationConsoleSection"; 
import DigitalImpactSection from "./DigitalImpactSection"; 
import CtaSection from "./CtaSection"; 
import ManifestoDock from "./ManifestoDock"; 
import styles from './HomeTerminal.module.css'; 

function HomeTerminal() {
  const [terminalInitialized, setTerminalInitialized] = useState(false);
  const [terminalReady, setTerminalReady] = useState(false);
  const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
  const [isManifestoOpen, setIsManifestoOpen] = useState(false); 
  const [isDockVisible, setIsDockVisible] = useState(true); // State for dock visibility

  // Sovereignty statements are now imported from data/homeContent.js
  
  // This function is passed down to child components that might log content
  const updateTerminalContent = (content) => {
    console.log('Terminal content updated:', content); 
  };
  
  useEffect(() => {
    // Initialize terminal
    const timer = setTimeout(() => {
      setTerminalInitialized(true);
      
      setTimeout(() => {
        setTerminalReady(true);
      }, 1000);
    }, 1500);
    
    // Rotate through sovereignty statements every 8 seconds
    const rotationInterval = setInterval(() => {
      setCurrentStatementIndex(prevIndex => (prevIndex + 1) % sovereigntyStatements.length);
    }, 8000);
    
    return () => {
      clearTimeout(timer);
      clearInterval(rotationInterval);
    };
  }, [sovereigntyStatements.length]);

  // Function to open the manifesto modal, passed to the dock
  const openManifesto = () => {
    setIsManifestoOpen(true);
  };

  // Function to hide the dock, passed to the dock
  const hideDock = () => {
    setIsDockVisible(false);
  };

  return (
    // Use module style for the main section
    <section className={styles.homeTerminalSection}> 
      {/* Digital Resistance Banner - Full Screen Hero Section with optimized layout */}
      {/* Use module style */}
      <div className={styles.fullScreenHero}> 
        {/* Use module style (or keep global if handled elsewhere) */}
        {/* Removed matrix and overlay divs */}

        {/* Removed global scanlines div */}


        {/* Main Hero Content */}
        {/* Use module style */}
        <div className={styles.heroContent}> 
          {/* Render the extracted HeroBanner component */}
          <HeroBanner /> 
            
          {/* Terminal and Identity Card Layout */}
          {/* Added a wrapper div for max-width and centering */}
          <div className={styles.contentWrapper}>
            <Container fluid>
              {/* Restored Row with Col wrappers for 7/5 split */}
              <Row>
                <Col md={7}> {/* Added Col wrapper */}
                  <TerminalDisplay
                    terminalInitialized={terminalInitialized}
                    terminalReady={terminalReady}
                    updateTerminalContent={updateTerminalContent}
                    // Added props for quote integration
                    currentStatementIndex={currentStatementIndex}
                    sovereigntyStatements={sovereigntyStatements}
                  />
                </Col>
                <Col md={5}> {/* Added Col wrapper */}
                  <IdentityCard
                    currentStatementIndex={currentStatementIndex}
                    sovereigntyStatements={sovereigntyStatements}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div> {/* End hero-content */}

        {/* Hero Footer */}
        {/* Use module style */}
        <div className={styles.heroFooter}> 
          <a href="#liberation-pathways" className={styles.scrollDownCta}>
            <span className={styles.scrollText}>EXPLORE LIBERATION PATHWAYS</span>
            <span className={styles.scrollArrow}>↓</span>
          </a>
        </div>
      </div> {/* End full-screen-hero */}
      
      {/* Render the extracted ManifestoPreview component */}
      <ManifestoPreview />
      
      {/* Render the extracted LiberationPathways component */}
      <LiberationPathways />
      
      {/* Render the extracted LiberationConsoleSection component */}
      <LiberationConsoleSection updateTerminalContent={updateTerminalContent} />
      
      {/* Render the extracted DigitalImpactSection component */}
      <DigitalImpactSection />

      {/* Render the extracted CtaSection component */}
      <CtaSection updateTerminalContent={updateTerminalContent} />
      
      {/* Render the extracted ManifestoDock component, passing state and handlers */}
      <ManifestoDock 
        onOpenManifesto={openManifesto} 
        onHide={hideDock} 
        isVisible={isDockVisible} 
      />

      {/* Render the modal component */}
      <HackerManifestoModal 
        isOpen={isManifestoOpen} 
        onClose={() => setIsManifestoOpen(false)} 
      />
    </section>
  );
}

export default HomeTerminal;
