import React from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { ImPointRight } from "react-icons/im";
import { FaAward, FaLaptopCode, FaShieldAlt, FaBrain, FaRocket } from "react-icons/fa";

function AboutCard() {
  const iconStyle = {
    fontSize: "1.5em",
    marginRight: "15px",
    background: "linear-gradient(45deg, #0ff, #f0f)",
    padding: "10px",
    borderRadius: "50%",
    boxShadow: "0 0 10px rgba(0, 255, 255, 0.5)"
  };

  const cardSectionStyle = {
    marginBottom: "1rem",
    padding: "15px",
    background: "rgba(0, 255, 255, 0.05)",
    borderRadius: "15px",
    border: "1px solid rgba(0, 255, 255, 0.1)",
    transition: "all 0.3s ease",
    height: "100%"
  };

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <Row>
            <Col md={6}>
              <div className="professional-summary" style={cardSectionStyle}>
                <h4 className="purple" style={{ 
                  marginBottom: "0.8rem",
                  fontSize: "1.6em",
                  letterSpacing: "2px"
                }}>
                  <FaBrain style={iconStyle} />
                  Professional Summary
                </h4>
                <p style={{ 
                  textAlign: "justify",
                  lineHeight: "1.5",
                  fontSize: "1.1em",
                  marginBottom: 0
                }}>
                  I am <span className="purple">Casey Tunturi</span>, a seasoned IT Professional based in 
                  <span className="purple"> Oregon, USA</span>. With extensive experience in cybersecurity, 
                  system architecture, and business technology solutions, I've built a reputation for delivering 
                  excellence across the country.
                </p>
              </div>
            </Col>

            <Col md={6}>
              <div className="achievements" style={cardSectionStyle}>
                <h4 className="purple" style={{ 
                  marginBottom: "0.8rem",
                  fontSize: "1.6em",
                  letterSpacing: "2px"
                }}>
                  <FaRocket style={iconStyle} />
                  Career Highlights
                </h4>
                <ul style={{ 
                  listStyle: "none", 
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                  marginBottom: 0
                }}>
                  <li style={{ 
                    display: "flex",
                    alignItems: "flex-start",
                    background: "rgba(0, 255, 255, 0.05)",
                    padding: "10px",
                    borderRadius: "10px",
                    transition: "all 0.3s ease"
                  }}>
                    <FaShieldAlt style={{
                      ...iconStyle,
                      minWidth: "45px",
                      padding: "8px"
                    }} />
                    <div>
                      <h5 style={{ color: "#0ff", marginBottom: "5px", fontSize: "1.1em" }}>Security Expert</h5>
                      Collaborated with MoxFive to implement robust security solutions for leading healthcare 
                      and insurance providers.
                    </div>
                  </li>
                  <li style={{ 
                    display: "flex",
                    alignItems: "flex-start",
                    background: "rgba(0, 255, 255, 0.05)",
                    padding: "10px",
                    borderRadius: "10px",
                    transition: "all 0.3s ease"
                  }}>
                    <FaLaptopCode style={{
                      ...iconStyle,
                      minWidth: "45px",
                      padding: "8px"
                    }} />
                    <div>
                      <h5 style={{ color: "#0ff", marginBottom: "5px", fontSize: "1.1em" }}>Tech Consultant</h5>
                      Provided expert IT consulting services to clients in major tech hubs including SF, NYC, LA, and Miami.
                    </div>
                  </li>
                  <li style={{ 
                    display: "flex",
                    alignItems: "flex-start",
                    background: "rgba(0, 255, 255, 0.05)",
                    padding: "10px",
                    borderRadius: "10px",
                    transition: "all 0.3s ease"
                  }}>
                    <FaAward style={{
                      ...iconStyle,
                      minWidth: "45px",
                      padding: "8px"
                    }} />
                    <div>
                      <h5 style={{ color: "#0ff", marginBottom: "5px", fontSize: "1.1em" }}>Business Leader</h5>
                      Successfully owned and operated a technology business, delivering innovative solutions.
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="interests" style={{...cardSectionStyle, marginTop: "1rem"}}>
            <h4 className="purple" style={{ 
              marginBottom: "0.8rem",
              fontSize: "1.6em",
              letterSpacing: "2px"
            }}>
              Professional Interests
            </h4>
            <ul style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gap: "0.8rem",
              padding: 0,
              marginBottom: 0
            }}>
              {[
                "Emerging Technologies & Innovation",
                "Cybersecurity Research",
                "Open Source Development",
                "Technical Content Creation",
                "Enterprise Architecture"
              ].map((interest, index) => (
                <li key={index} className="about-activity" style={{
                  background: "rgba(0, 255, 255, 0.05)",
                  padding: "8px 12px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s ease",
                  fontSize: "0.95em"
                }}>
                  <ImPointRight style={{
                    color: "#0ff",
                    marginRight: "10px",
                    fontSize: "1em"
                  }} />
                  {interest}
                </li>
              ))}
            </ul>
          </div>

          <p style={{ 
            color: "#0ff",
            borderLeft: "4px solid #0ff",
            paddingLeft: "1.2rem",
            marginTop: "1rem",
            fontSize: "1.1em",
            fontStyle: "italic",
            background: "rgba(0, 255, 255, 0.05)",
            padding: "12px",
            borderRadius: "0 10px 10px 0",
            boxShadow: "0 0 20px rgba(0, 255, 255, 0.1)"
          }}>
            "Innovation and security are not opposing forces - they're the foundation of modern technology."
          </p>
          <footer className="blockquote-footer" style={{
            fontSize: "1em",
            marginTop: "0.5rem",
            textAlign: "right"
          }}>Casey Tunturi</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
