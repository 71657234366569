// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: var(--glass-bg) !important;
  border: 1px solid var(--glass-border) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--secondary-color);
}

.home-social-icons:hover {
  color: var(--secondary-color);
  box-shadow: 0 0 5px var(--secondary-color);
  text-shadow: 0 0 2px var(--secondary-color);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/social.css"],"names":[],"mappings":"AAEA;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,sBAAsB;EACtB,uBAAuB;EACvB,6BAA6B;EAC7B,2BAA2B;EAC3B,2BAA2B;EAC3B,sCAAsC;EACtC,gDAAgD;EAChD,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gFAAgF;EAChF,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,2CAA2C;AAC7C;;AAEA;EACE,6BAA6B;EAC7B,0CAA0C;EAC1C,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@import './variables.css';\n\n.home-social-icons {\n  position: relative !important;\n  display: inline-block !important;\n  width: 40px !important;\n  height: 40px !important;\n  text-align: center !important;\n  font-size: 1.2em !important;\n  line-height: 2em !important;\n  background: var(--glass-bg) !important;\n  border: 1px solid var(--glass-border) !important;\n  border-radius: 50% !important;\n  transition: 0.5s !important;\n}\n\n.home-social-icons::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));\n  transition: 0.5s;\n  transform: scale(0.9);\n  z-index: -1;\n}\n\n.home-social-icons:hover::before {\n  transform: scale(1.1);\n  box-shadow: 0 0 15px var(--secondary-color);\n}\n\n.home-social-icons:hover {\n  color: var(--secondary-color);\n  box-shadow: 0 0 5px var(--secondary-color);\n  text-shadow: 0 0 2px var(--secondary-color);\n}\n\n.social-icons {\n  display: inline-block !important;\n  padding-right: 15px;\n  padding-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
