// main-site/bio/src/data/arsenalItems/markdown.js
import React from 'react';
import { FaFileAlt, FaBook, FaExternalLinkAlt, FaFileCode, FaHtml5 } from "react-icons/fa"; // Added icons

const markdown = {
  id: "markdown",
  name: "Markdown",
  icon: <FaFileAlt />,
  category: "development",
  description: "Lightweight markup language for creating formatted documents with plain text syntax",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Documentation system foundation",
    "Knowledge base articles",
    "Technical specifications and guides"
  ],
  // Additional details for expanded view
  detailedDescription: "Markdown is a lightweight markup language with plain-text formatting syntax. It's designed so that it can be converted to HTML and many other formats using a tool by the same name. Markdown is often used to format readme files, for writing messages in online discussion forums, and to create rich text using a plain text editor.",
  advantages: [
    "Simple and intuitive syntax, easy to learn and read",
    "Plain text format ensures portability and longevity",
    "Widely supported across platforms and applications (GitHub, Reddit, text editors, etc.)",
    "Easily convertible to HTML and other formats",
    "Focuses on content structure rather than complex formatting commands"
  ],
  alternatives: [
    { name: "reStructuredText (rST)", icon: <FaFileCode />, sovereignty: 5 },
    { name: "AsciiDoc", icon: <FaFileCode />, sovereignty: 5 },
    { name: "HTML", icon: <FaHtml5 />, sovereignty: 5 }, // More complex
    { name: "WYSIWYG Editors (e.g., Word)", icon: <FaFileCode />, sovereignty: 1 } // Proprietary formats
  ],
  resources: [
    {
      title: "Markdown Guide",
      url: "https://www.markdownguide.org/",
      icon: <FaBook />
    },
    {
      title: "CommonMark Spec",
      url: "https://commonmark.org/",
      icon: <FaExternalLinkAlt />
    }
  ],
  installationSteps: [
    "Markdown itself is a syntax, not software to install for writing.",
    "Choose a text editor with Markdown support/preview (e.g., VS Code, Obsidian, Typora).",
    "Learn the basic syntax (headings, lists, bold, italics, links, images).",
    "Use Markdown parsers/converters (like Pandoc, Marked.js) if needed to transform to other formats.",
    "Practice writing!"
  ]
};

export default markdown;
