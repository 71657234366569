// main-site/bio/src/data/arsenalItems/linux.js
import React from 'react';
import { FaLinux, FaServer, FaExternalLinkAlt } from "react-icons/fa";

const linux = {
  id: "linux",
  name: "Linux",
  icon: <FaLinux />,
  category: "infrastructure",
  description: "Open-source operating system kernel that powers everything from embedded devices to supercomputers",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Server operating systems",
    "Desktop liberation from proprietary OS",
    "Infrastructure automation foundation"
  ],
  // Additional details for expanded view
  detailedDescription: "Linux is the foundation of digital sovereignty - an open-source operating system kernel that forms the backbone of countless computing environments. From Android phones to 99% of supercomputers, Linux dominates the digital landscape. Its open design, collaborative development model, and unparalleled flexibility make it the ultimate liberation technology.",
  advantages: [
    "Complete transparency with open-source code that can be audited and modified",
    "Zero licensing costs with freedom to deploy without artificial limitations",
    "Exceptional stability and security with rapid community-driven updates",
    "Unmatched flexibility to run on virtually any hardware platform",
    "Rich ecosystem of distributions tailored for specific use cases"
  ],
  alternatives: [
    { name: "Microsoft Windows", icon: <FaServer />, sovereignty: 1 },
    { name: "Apple macOS", icon: <FaServer />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Kernel.org",
      url: "https://www.kernel.org/",
      icon: <FaExternalLinkAlt />
    },
    {
      title: "Linux Foundation",
      url: "https://www.linuxfoundation.org/",
      icon: <FaExternalLinkAlt />
    }
  ],
  installationSteps: [
    "Choose a distribution that meets your specific needs (Ubuntu, Debian, Fedora, etc.)",
    "Create installation media (USB drive or DVD)",
    "Boot from installation media and follow the guided setup",
    "Configure disk partitioning, user accounts, and network settings",
    "Post-installation, update and install necessary software packages"
  ]
};

export default linux;
