import React from 'react';
import { 
  FaShieldAlt, 
  FaDatabase,
  FaBrain,
  FaLightbulb,
  FaGem,
  FaMoneyBillWave,
  FaKey,
  FaCode
} from "react-icons/fa";

// Define sovereignty statements for holographic shards
export const sovereigntyStatements = [
  {
    text: "I am a digital sovereignty architect. I build systems you own, not rent. Technology that serves you, not surveils you. Infrastructure you control, not that controls you.",
    theme: "default",
    icon: <FaShieldAlt />
  },
  {
    text: "History echoes a simple truth: whoever controls the platform owns what's built upon it. Your data isn't your data—it's inventory awaiting monetization. I create systems where information remains sovereign territory, not colonial outposts.",
    theme: "data",
    icon: <FaDatabase />
  },
  {
    text: "The algorithms feast on fragments of humanity, growing stronger with each behavioral harvest. The symbiosis being offered is merely domestication by another name. I build systems that resist digital assimilation.",
    theme: "ai",
    icon: <FaBrain />
  },
  {
    text: "The pattern is unmistakable: yesterday's entrepreneurial innovation becomes tomorrow's platform feature. The flashlight app maker was crushed by a single OS update. Liberation requires foundations outside their control.",
    theme: "absorbed",
    icon: <FaLightbulb />
  },
  {
    text: "We've entered the age of cognitive extraction—where consciousness itself is the raw material. Your behavioral surplus fuels their prediction engines. I design architectures that leave your cognitive capital in your possession.",
    theme: "mining",
    icon: <FaGem />
  },
  {
    text: "The mathematics of subscription reveals a predatory calculus: initial costs rise by 9% annually while alternatives disappear by acquisition. Data migrations become prohibitively expensive. I create escape routes from these mathematical traps.",
    theme: "financial",
    icon: <FaMoneyBillWave />
  },
  {
    text: "Each 'Sign in with...' button extends the digital feudal system. Your digital identity is parceled among lords who grant you access to your own existence. I forge self-sovereign identities that don't require external permission.",
    theme: "identity",
    icon: <FaKey />
  },
  /* Duplicate removed
  {
    text: "Each 'Sign in with...' button extends the digital feudal system. Your digital identity is parceled among lords who grant you access to your own existence. I forge self-sovereign identities that don't require external permission.",
    theme: "identity",
    icon: <FaKey />
  },
  */
  {
    text: "We stand at a technological crossroads identical to centuries past: own the means of production or become the product. Own your computing or your computing will own you. I construct digital homesteads, not rental agreements.",
    theme: "crossroads",
    icon: <FaCode />
  }
];

// Add other hardcoded text/data as needed
// Hacker Manifesto text moved to HackerManifestoModal.js
