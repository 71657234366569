// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #8B5CF6;
  --secondary-color: #3B82F6;
  --imp-text-color: #A78BFA;
  --background-start: rgba(76, 29, 149, 0.85);
  --background-end: rgba(30, 58, 138, 0.95);
  --section-background-color: linear-gradient(to bottom left, 
    rgba(76, 29, 149, 0.582),
    rgba(30, 58, 138, 0.904)
  );
  --image-gradient: linear-gradient(to bottom left,
    rgba(76, 29, 149, 0.678),
    rgba(30, 58, 138, 0.863)
  );
  --glass-bg: rgba(255, 255, 255, 0.05);
  --glass-border: rgba(255, 255, 255, 0.1);
  --glass-shadow: rgba(0, 0, 0, 0.2);
  --text-primary: #FFFFFF;
  --text-secondary: #E2E8F0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,yBAAyB;EACzB,2CAA2C;EAC3C,yCAAyC;EACzC;;;GAGC;EACD;;;GAGC;EACD,qCAAqC;EACrC,wCAAwC;EACxC,kCAAkC;EAClC,uBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[":root {\n  --primary-color: #8B5CF6;\n  --secondary-color: #3B82F6;\n  --imp-text-color: #A78BFA;\n  --background-start: rgba(76, 29, 149, 0.85);\n  --background-end: rgba(30, 58, 138, 0.95);\n  --section-background-color: linear-gradient(to bottom left, \n    rgba(76, 29, 149, 0.582),\n    rgba(30, 58, 138, 0.904)\n  );\n  --image-gradient: linear-gradient(to bottom left,\n    rgba(76, 29, 149, 0.678),\n    rgba(30, 58, 138, 0.863)\n  );\n  --glass-bg: rgba(255, 255, 255, 0.05);\n  --glass-border: rgba(255, 255, 255, 0.1);\n  --glass-shadow: rgba(0, 0, 0, 0.2);\n  --text-primary: #FFFFFF;\n  --text-secondary: #E2E8F0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
