// main-site/bio/src/data/arsenalItems/grafana.js
import React from 'react';
import { SiGrafana } from "react-icons/si";
import { FaBook, FaGithub, FaChartLine } from "react-icons/fa"; // Added icons

const grafana = {
  id: "grafana",
  name: "Grafana",
  icon: <SiGrafana />,
  category: "infrastructure",
  description: "Open-source analytics and monitoring platform with beautiful dashboards and extensive data source support",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Infrastructure visualization and monitoring",
    "Security operations dashboards",
    "Business analytics interfaces"
  ],
  // Additional details for expanded view
  detailedDescription: "Grafana is a leading open-source platform for monitoring and observability. It allows you to query, visualize, alert on, and understand your metrics no matter where they are stored. Create, explore, and share dashboards with your team and foster a data-driven culture.",
  advantages: [
    "Supports a wide variety of data sources (Prometheus, InfluxDB, Elasticsearch, etc.)",
    "Highly customizable dashboards with rich visualization options",
    "Powerful alerting engine to notify on critical metric changes",
    "Extensible via a large ecosystem of plugins and integrations",
    "Active open-source community and optional enterprise support"
  ],
  alternatives: [
    { name: "Kibana (Elastic Stack)", icon: <FaChartLine />, sovereignty: 4 },
    { name: "Datadog", icon: <FaChartLine />, sovereignty: 1 },
    { name: "Splunk", icon: <FaChartLine />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://grafana.com/docs/grafana/latest/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/grafana/grafana",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Install Grafana using package manager, Docker, or binary download",
    "Start the Grafana server service",
    "Access the web UI (default: http://localhost:3000)",
    "Log in with default credentials (admin/admin) and change password",
    "Add data sources (e.g., Prometheus, InfluxDB)",
    "Create dashboards and panels to visualize data"
  ]
};

export default grafana;
