import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaLock } from "react-icons/fa";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <div className="project-card-img-container">
        <Card.Img 
          variant="top" 
          src={props.imgPath} 
          alt="card-img" 
          className="project-card-img"
        />
        <div className="project-card-overlay">
          <FaLock className="project-card-lock-icon" />
          <span>Enterprise Project</span>
        </div>
      </div>
      
      <Card.Body className="project-card-body">
        <Card.Title className="project-card-title">
          {props.title}
        </Card.Title>
        
        <Card.Text className="project-card-text">
          {props.description}
        </Card.Text>
        
        <div className="project-card-buttons">
          {props.ghLink && (
            <Button 
              variant="primary" 
              href={props.ghLink} 
              target="_blank"
              className="project-card-button"
            >
              <BsGithub className="project-card-button-icon" />
              <span>Repository</span>
            </Button>
          )}

          {!props.isBlog && props.demoLink && (
            <Button
              variant="primary"
              href={props.demoLink}
              target="_blank"
              className="project-card-button"
            >
              <CgWebsite className="project-card-button-icon" />
              <span>Live Demo</span>
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
