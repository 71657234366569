import React, { useState, useEffect } from 'react';

const DigitalSignature = ({
  signature = 'DIGITAL_RESISTANCE',
  size = 'medium', 
  animate = true,
  rotation = 0,
  className = '',
  style = {}
}) => {
  const [randomId] = useState(`digital-signature-${Math.random().toString(36).substr(2, 9)}`);
  const [visible, setVisible] = useState(false);
  const [loadingState, setLoadingState] = useState(0);
  
  // Size calculation
  const getSize = () => {
    switch(size) {
      case 'small': return { width: 100, height: 40, fontSize: 8 };
      case 'large': return { width: 250, height: 100, fontSize: 12 };
      case 'medium':
      default: return { width: 180, height: 70, fontSize: 10 };
    }
  };
  
  const { width, height, fontSize } = getSize();
  
  useEffect(() => {
    if (animate) {
      // Simulate digital signature verification
      const timer1 = setTimeout(() => setLoadingState(1), 300);
      const timer2 = setTimeout(() => setLoadingState(2), 600);
      const timer3 = setTimeout(() => setLoadingState(3), 900);
      const timer4 = setTimeout(() => setLoadingState(4), 1200);
      const timer5 = setTimeout(() => setLoadingState(5), 1500);
      const timer6 = setTimeout(() => setVisible(true), 1800);
      
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
        clearTimeout(timer5);
        clearTimeout(timer6);
      };
    } else {
      setVisible(true);
    }
  }, [animate]);
  
  // Generate hash-like code from signature
  const generateHash = () => {
    const characters = '0123456789abcdef';
    let hash = '';
    
    for (let i = 0; i < 40; i++) {
      hash += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    
    return hash;
  };
  
  // Generate the QR-like pattern
  const generatePattern = () => {
    // Create a pseudo-random pattern based on the signature
    const seed = signature.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const patternSize = 8; // 8x8 grid
    const pattern = [];
    
    for (let i = 0; i < patternSize; i++) {
      const row = [];
      for (let j = 0; j < patternSize; j++) {
        // Use a deterministic approach based on position and seed
        const value = Math.sin(i * j + seed) > 0;
        row.push(value);
      }
      pattern.push(row);
    }
    
    return pattern;
  };
  
  // Combined classes and styles
  const combinedClasses = `digital-signature ${className}`;
  const combinedStyles = {
    ...style,
    transform: `rotate(${rotation}deg)`
  };
  
  const renderLoading = () => {
    return (
      <div className="digital-signature-loading">
        <div className="loading-text">
          {loadingState >= 1 && <div>Verifying identity...</div>}
          {loadingState >= 2 && <div>Generating keys...</div>}
          {loadingState >= 3 && <div>Computing signature...</div>}
          {loadingState >= 4 && <div>Validating certificate...</div>}
          {loadingState >= 5 && <div>Signature ready.</div>}
        </div>
      </div>
    );
  };
  
  const renderSignature = () => {
    // Generate a unique pattern based on the signature
    const pattern = generatePattern();
    const hash = generateHash();
    
    // Create date string
    const now = new Date();
    const dateStr = now.toISOString().split('T')[0];
    
    return (
      <svg 
        width={width} 
        height={height} 
        viewBox={`0 0 ${width} ${height}`}
        className="signature-svg"
      >
        {/* Background */}
        <rect x="0" y="0" width={width} height={height} fill="#0d1117" />
        
        {/* Border */}
        <rect 
          x="2" 
          y="2" 
          width={width - 4} 
          height={height - 4} 
          fill="none" 
          stroke="#3b82f6" 
          strokeWidth="1" 
          strokeDasharray="2,2"
        />
        
        {/* Digital pattern */}
        <g transform={`translate(${width - 35}, 10) scale(0.3)`}>
          {pattern.map((row, rowIndex) => 
            row.map((cell, colIndex) => 
              cell && (
                <rect
                  key={`${rowIndex}-${colIndex}`}
                  x={colIndex * 5}
                  y={rowIndex * 5}
                  width="4"
                  height="4"
                  fill="#3b82f6"
                />
              )
            )
          )}
        </g>
        
        {/* Signature name */}
        <text
          x={width / 2}
          y="15"
          fontFamily="'Fira Code', monospace"
          fontSize={fontSize + 2}
          fontWeight="bold"
          fill="#3b82f6"
          textAnchor="middle"
        >
          {signature}
        </text>
        
        {/* Horizontal line */}
        <line
          x1="10"
          y1="20"
          x2={width - 10}
          y2="20"
          stroke="#3b82f6"
          strokeWidth="1"
        />
        
        {/* Crypto hash */}
        <text
          x={width / 2}
          y="35"
          fontFamily="'Fira Code', monospace"
          fontSize={fontSize - 4}
          fill="#10b981"
          textAnchor="middle"
        >
          {hash.substring(0, width / 6)}
        </text>
        
        {/* Validation date */}
        <text
          x="10"
          y={height - 10}
          fontFamily="'Fira Code', monospace"
          fontSize={fontSize - 2}
          fill="#60a5fa"
        >
          DATE: {dateStr}
        </text>
        
        {/* Validation checkmark */}
        <g transform={`translate(${width - 25}, ${height - 15})`}>
          <circle cx="0" cy="0" r="7" fill="#10b981" fillOpacity="0.5" />
          <path
            d="M-3,0 L-1,2 L3,-2"
            stroke="#ffffff"
            strokeWidth="1.5"
            fill="none"
          />
        </g>
      </svg>
    );
  };
  
  return (
    <div id={randomId} className={combinedClasses} style={combinedStyles}>
      {!visible ? renderLoading() : renderSignature()}
      
      <style jsx="true">{`
        .digital-signature {
          display: inline-block;
          position: relative;
          filter: drop-shadow(0 0 5px rgba(59, 130, 246, 0.5));
        }
        
        .digital-signature-loading {
          width: ${width}px;
          height: ${height}px;
          background-color: #0d1117;
          border: 1px solid #3b82f6;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #60a5fa;
          font-family: 'Fira Code', monospace;
          font-size: ${fontSize}px;
        }
        
        .loading-text div {
          margin: 3px 0;
          opacity: 0;
          animation: fade-in 0.3s forwards;
        }
        
        @keyframes fade-in {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        .signature-svg {
          animation: signature-appear 0.5s forwards;
          opacity: 0;
        }
        
        @keyframes signature-appear {
          from {
            opacity: 0;
            transform: scale(0.9);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
};

export default DigitalSignature;