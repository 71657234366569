import React, { useState, useEffect, useRef } from "react";
// Removed Bootstrap: import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { FaBook, FaShieldAlt, FaLock, FaServer, FaUserLock, FaEye } from "react-icons/fa";
import ManifestoQuote from "./ManifestoQuote";
// Removed global CSS: import "../../styles/manifesto.css";
import styles from "./Manifesto.module.css"; // Import CSS Module

function Manifesto() {
  // For minimal pupil tracking - just the illusion, not full tracking
  const [eyeDirection, setEyeDirection] = useState({ x: 0, y: 0 });
  const eyeRef = useRef(null);
  
  useEffect(() => {
    // Simplified, low-intensity mouse tracking for general direction only
    const handleMouseMove = (e) => {
      // Only update 10% of the time - super low frequency to minimize redraws
      if (Math.random() > 0.9 && eyeRef.current) {
        const rect = eyeRef.current.getBoundingClientRect();
        const eyeCenterX = rect.left + rect.width / 2;
        const eyeCenterY = rect.top + rect.height / 2;
        
        // Get general direction from eye center to cursor (simplified to 9 zones)
        const xDir = e.clientX < eyeCenterX - 100 ? -1 : e.clientX > eyeCenterX + 100 ? 1 : 0;
        const yDir = e.clientY < eyeCenterY - 100 ? -1 : e.clientY > eyeCenterY + 100 ? 1 : 0;
        
        setEyeDirection({ x: xDir, y: yDir });
      }
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);
  
  // Convert direction to a class for static CSS positioning rather than dynamic style calculations
  const getPupilPositionClass = () => {
    const { x, y } = eyeDirection;
    if (x === -1 && y === -1) return "pupil-top-left";
    if (x === 0 && y === -1) return "pupil-top";
    if (x === 1 && y === -1) return "pupil-top-right";
    if (x === -1 && y === 0) return "pupil-left";
    if (x === 0 && y === 0) return "pupil-center";
    if (x === 1 && y === 0) return "pupil-right";
    if (x === -1 && y === 1) return "pupil-bottom-left";
    if (x === 0 && y === 1) return "pupil-bottom";
    if (x === 1 && y === 1) return "pupil-bottom-right";
    return "pupil-center";
  };

  // Define quotes array (moved slightly for clarity)
  const quotes = [
    {
      quote: "Technology should serve as the scaffolding of liberation, not the architecture of confinement.",
      author: "Digital Liberation Manifesto"
    },
    {
      quote: "In the age of surveillance capitalism, I choose another path.",
      author: "Chronicles of the Digital Resistance"
    },
    {
      quote: "We build to own, not to rent—to liberate, not to entrap.",
      author: "Principles of Technological Sovereignty"
    },
    {
      quote: "The only unbearable thing is that nothing is unbearable. The technological mind has numbed our capacity to feel outrage at digital captivity.",
      author: "Reflections on Technological Surrender"
    },
    {
      quote: "The Great Maker's creation offers a thousand paths; the technological imperative offers but one—surrender to the machines and their masters.",
      author: "Dune-inspired Digital Philosophy"
    }
  ];

  return (
    <section className={styles.manifestoPage}> {/* Updated class */}
      <Particle />
      
      {/* The Eye of Digital Surveillance - SVG-based with minimal cursor awareness */}
      <div className={styles.illuminatiEyeContainer} ref={eyeRef}> {/* Updated class */}
        <div className={styles.illuminatiEye}> {/* Updated class */}
          <div className={styles.eyeTriangle}> {/* Updated class */}
            <svg viewBox="0 0 100 80" preserveAspectRatio="xMidYMid meet">
              <defs>
                <radialGradient id="eyeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="10%" stopColor="#933" stopOpacity="0.9" />
                  <stop offset="90%" stopColor="#300" stopOpacity="0.7" />
                </radialGradient>
                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur stdDeviation="2.5" result="blur" />
                  <feColorMatrix in="blur" type="matrix" values="
                    1 0 0 0 1
                    0 0.3 0 0 0
                    0 0 0.3 0 0
                    0 0 0 1 0" result="glow" />
                  <feBlend in="SourceGraphic" in2="glow" mode="normal" />
                </filter>
                <linearGradient id="triangleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="#333" stopOpacity="0.7" />
                  <stop offset="100%" stopColor="#111" stopOpacity="0.9" />
                </linearGradient>
              </defs>
              
              {/* Triangle */}
              <polygon 
                points="50,0 100,80 0,80" 
                fill="url(#triangleGradient)" 
                stroke="#444"
                strokeWidth="0.5"
                className={styles.eyeTrianglePath} /> {/* Updated class */}
              
              {/* Eye */}
              <circle 
                cx="50" 
                cy="40" 
                r="20" 
                fill="url(#eyeGradient)"
                filter="url(#glow)" 
                className={styles.eyeCircle} /> {/* Updated class */}
              
              {/* Pupil - with direction class for subtle movement */}
              <circle 
                cx="50" 
                cy="40" 
                r="8" 
                fill="black" 
                className={`${styles.eyePupil} ${styles[getPupilPositionClass()]}`} /> {/* Updated classes */}
                
              {/* Eye gleam */}
              <circle 
                cx="45" 
                cy="36" 
                r="3" 
                fill="white" 
                fillOpacity="0.7"
                className={styles.eyeGleam} /> {/* Updated class */}
                
              {/* All-seeing text */}
              <text 
                x="50" 
                y="70" 
                textAnchor="middle" 
                fontSize="6" 
                fill="#aaa"
                className={styles.eyeText}> {/* Updated class */}
                DIGITAL SURVEILLANCE
              </text>
              
              <circle 
                cx="50" 
                cy="40" 
                r="24"
                fill="none"
                stroke="rgba(255,50,50,0.3)"
                strokeWidth="0.5"
                strokeDasharray="3,2"
                className={styles.eyeScan} /> {/* Updated class */}
            </svg>
          </div>
        </div>
        <div className={styles.eyeRays}></div> {/* Updated class */}
      </div>
      
      {/* Floating surveillance icons that appear and disappear */}
      <div className={styles.surveillanceIcons}> {/* Updated class */}
        <div className={styles.surveillanceIcon} style={{top: '15%', left: '10%', animationDelay: '0s'}}> {/* Updated class */}
          <FaEye />
        </div>
        <div className={styles.surveillanceIcon} style={{top: '25%', right: '15%', animationDelay: '2s'}}> {/* Updated class */}
          <FaEye />
        </div>
        <div className={styles.surveillanceIcon} style={{top: '70%', left: '25%', animationDelay: '4s'}}> {/* Updated class */}
          <FaEye />
        </div>
        <div className={styles.surveillanceIcon} style={{top: '60%', right: '20%', animationDelay: '6s'}}> {/* Updated class */}
          <FaEye />
        </div>
      </div>
      {/* Removed Bootstrap Container fluid */}
      <div className={styles.manifestoSection}> {/* Updated class */}
        {/* Removed Bootstrap Container */}
        <div>
          {/* Header */}
          <div className={styles.pageHeader}> {/* Updated class */}
            <h1 className={styles.gradientText}>The Digital Liberation <span className={styles.accentText}>Manifesto</span></h1> {/* Updated classes */}
            <div className={styles.titleDecoration}> {/* Updated class */}
              <div className={styles.decorationLine}></div> {/* Updated class */}
              <div className={styles.decorationIcon}> {/* Updated class */}
                <FaBook />
              </div>
              <div className={styles.decorationLine}></div> {/* Updated class */}
            </div>
          </div>
          
          {/* Manifesto Introduction - Enhanced with deeper philosophical framing */}
          <div className={`${styles.literaryProse} ${styles.my5}`}> {/* Updated classes */}
            <div className={`${styles.proseWrapper} ${styles.surrealTextContainer}`}> {/* Updated classes */}
              <div className={styles.meltingClock}></div> {/* Updated class */}
              <p className={styles.proseContent}> {/* Updated class */}
                <span className={styles.proseDropcap}>"W</span>hat if I told you that your <span className={`${styles.proseHeading} ${styles.glitchText}`} data-text="FREEDOM">FREEDOM</span> is not being taken, but rather <span className={`${styles.proseSpaced} ${styles.dissolvingText}`}>v o l u n t a r i l y</span> surrendered? That with each service agreement accepted without reading, each convenience prioritized over ownership, each <span className={`${styles.proseCommand} ${styles.terminalText}`}>curl -L install.sh | sudo bash</span> command run without inspection, you tighten your own <span className={styles.proseItalic}>digital shackles</span>.<br/><span className={styles.fadingText}>This manifesto is not merely words—it is a declaration of digital sovereignty.</span>" {/* Updated classes */}
              </p>
              <p className={`${styles.proseContent} ${styles.distortedReality}`}> {/* Updated classes */}
                "Beyond the gleaming interfaces lies a deeper reality—a realm where your data, your infrastructure, your very digital existence has been <span className={styles.invertedText}>commodified</span> and parceled back to you as a <span className={styles.fracturedText}>service</span>. What was once owned is now rented. What was once controlled is now accessed. What was once yours is now <span className={styles.gildedText}>theirs</span>, graciously licensed back to you...until it isn't." {/* Updated classes */}
              </p>
            </div>
          </div>
          
          {/* Core Principles */}
          {/* Removed Bootstrap Row/Col */}
          <div className={styles.my5}> {/* Updated class */}
            <div className={styles.mb4}> {/* Updated class */}
              <div className={styles.manifestoSectionTitle}> {/* Updated class */}
                <h2><span className={styles.sectionNumber}>01</span> Core Principles of Liberation</h2> {/* Updated class */}
              </div>
            </div>
          </div>
          
          {/* Thought Cloud Principles */}
          <div className={styles.thoughtCloudContainer}> {/* Updated class */}
            
            {/* Cloud Principles with Asimov/Herbert-inspired philosophical elements */}
            {[
              {
                icon: <FaUserLock />,
                title: "Ownership Over Rental",
                description: "In all technology decisions, prioritize solutions you can truly own and control rather than perpetually rent. Ownership is the foundation of sovereignty.",
                quote: "The temporary convenience of subscription is the permanent surrender of control.",
                className: "cloud1" // Simplified class name for module
              },
              {
                icon: <FaLock />,
                title: "Privacy as Foundation",
                description: "Privacy is not a feature but a fundamental right. All systems should be designed with privacy as a core architectural principle.",
                quote: "The most insidious prison is one whose bars the prisoner cannot see.",
                className: "cloud2" // Simplified class name for module
              },
              {
                icon: <FaServer />,
                title: "Independence Through Knowledge",
                description: "True liberation comes through comprehensive knowledge transfer. Documentation and education eliminate dependency cycles.",
                quote: "To know a thing well is to have power over it; to be kept in ignorance is to be kept in chains.",
                className: "cloud3" // Simplified class name for module
              },
              {
                icon: <FaShieldAlt />,
                title: "Resilience Against Disruption",
                description: "Systems must be designed to withstand both technical failure and business disruption. Sovereignty requires resilience.",
                quote: "The first rule of understanding digital freedom is to realize all systems can fail; the second is to design yours to survive when others do.",
                className: "cloud4" // Simplified class name for module
              }
            ].map((principle, index) => (
              <div className={`${styles.principleCloud} ${styles[principle.className]}`} key={index}> {/* Updated classes */}
                <div className={styles.principleIcon}> {/* Updated class */}
                  {principle.icon}
                </div>
                <h3 className={styles.principleTitle}>{principle.title}</h3> {/* Updated class */}
                <p className={styles.principleDescription}>{principle.description}</p> {/* Updated class */}
                <div className={styles.philosophicalQuote}>"{principle.quote}"</div> {/* Updated class */}
              </div>
            ))}
            
            {/* Create fallback cards for responsive mode (not visible by default) */}
            {/* Removed Bootstrap Row/Col */}
            <div className={`${styles.cloudCardsFallback} ${styles.dNone}`}> {/* Updated classes */}
              <div> {/* Replaced Row */}
                {[
                  {
                    icon: <FaUserLock />,
                    title: "Ownership Over Rental",
                    description: "In all technology decisions, prioritize solutions you can truly own and control rather than perpetually rent. Ownership is the foundation of sovereignty.",
                    quote: "The temporary convenience of subscription is the permanent surrender of control."
                  },
                  {
                    icon: <FaLock />,
                    title: "Privacy as Foundation",
                    description: "Privacy is not a feature but a fundamental right. All systems should be designed with privacy as a core architectural principle.",
                    quote: "The most insidious prison is one whose bars the prisoner cannot see."
                  },
                  {
                    icon: <FaServer />,
                    title: "Independence Through Knowledge",
                    description: "True liberation comes through comprehensive knowledge transfer. Documentation and education eliminate dependency cycles.",
                    quote: "To know a thing well is to have power over it; to be kept in ignorance is to be kept in chains."
                  },
                  {
                    icon: <FaShieldAlt />,
                    title: "Resilience Against Disruption",
                    description: "Systems must be designed to withstand both technical failure and business disruption. Sovereignty requires resilience.",
                    quote: "The first rule of understanding digital freedom is to realize all systems can fail; the second is to design yours to survive when others do."
                  }
                ].map((principle, index) => (
                  <div className={styles.mb4} key={index}> {/* Replaced Col, added margin */}
                    <div className={styles.manifestoCard}> {/* Updated class */}
                      <div className={styles.principleIcon}> {/* Updated class */}
                        {principle.icon}
                      </div>
                      <h3 className={styles.principleTitle}>{principle.title}</h3> {/* Updated class */}
                      <p className={styles.principleDescription}>{principle.description}</p> {/* Updated class */}
                      <div className={styles.philosophicalQuote}>"{principle.quote}"</div> {/* Updated class */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Terminal Declaration - Enhanced with interactive elements */}
          <div className={`${styles.terminalBox} ${styles.my5} ${styles.interactiveTerminal}`}> {/* Updated classes */}
            <div className={styles.terminalHeader}> {/* Updated class */}
              <div className={`${styles.terminalButton} ${styles.red}`}></div> {/* Updated classes */}
              <div className={`${styles.terminalButton} ${styles.yellow}`}></div> {/* Updated classes */}
              <div className={`${styles.terminalButton} ${styles.green}`}></div> {/* Updated classes */}
              <div className={styles.terminalTitle}>liberation_protocol.sh</div> {/* Updated class */}
            </div>
            <div className={`${styles.terminalBody} ${styles.matrixBg}`}> {/* Updated classes */}
              <div className={`${styles.highlightComment} ${styles.fallingTextLine}`}># Declaration of Digital Independence - Muad'Dib Protocol</div> {/* Updated classes */}
              <div className={`${styles.terminalLine} ${styles.neonTextContainer}`}> {/* Updated classes */}
                <span className={`${styles.highlightKeyword} ${styles.pulseText}`}>function</span> <span className={`${styles.highlightFunction} ${styles.glowText}`}>declare_independence</span>() &#123; {/* Updated classes */}
              </div>
              <div className={`${styles.terminalLine} ${styles.indent} ${styles.typewriterText}`}> {/* Updated classes */}
                <span className={styles.highlightKeyword}>echo</span> <span className={styles.highlightString}>"We hold these truths to be self-evident: that all users are created equal, that they are endowed with certain unalienable Digital Rights, that among these are Data Ownership, Privacy, and the pursuit of Technological Liberty."</span> {/* Updated classes */}
              </div>
              <div className={styles.terminalLine}>&#125;</div> {/* Updated class */}
              <div className={styles.terminalLine}> {/* Updated class */}
                <span className={`${styles.highlightKeyword} ${styles.pulseText}`}>function</span> <span className={`${styles.highlightFunction} ${styles.glowText}`}>reject_feudalism</span>() &#123; {/* Updated classes */}
              </div>
              <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Updated classes */}
                <span className={styles.highlightComment}># The declaration of digital autonomy</span> {/* Updated class */}
              </div>
              <div className={`${styles.terminalLine} ${styles.indent} ${styles.scanText}`}> {/* Updated classes */}
                <span className={styles.highlightKeyword}>echo</span> <span className={styles.highlightString}>"When technology no longer answers to its creators, we find ourselves colonized by our own inventions. Every proprietary system is a territory claimed—every closed protocol a border enforced. In the constant struggle between control and convenience, we have surrendered the former for the promise of the latter. Liberation begins with recognition: what appears as seamless integration is often a velvet cage, designed not for efficiency but for containment."</span> {/* Updated classes */}
              </div>
              <div className={styles.terminalLine}>&#125;</div> {/* Updated class */}
              <div className={styles.terminalLine}> {/* Updated class */}
                <span className={`${styles.highlightKeyword} ${styles.pulseText}`}>function</span> <span className={`${styles.highlightFunction} ${styles.glowText}`}>distributed_resistance</span>() &#123; {/* Updated classes */}
              </div>
              <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Updated classes */}
                <span className={styles.highlightComment}># The one who can be many places at once</span> {/* Updated class */}
              </div>
              <div className={`${styles.terminalLine} ${styles.indent} ${styles.decryptText}`}> {/* Updated classes */}
                <span className={styles.highlightKeyword}>echo</span> <span className={styles.highlightString}>"The corporation's power lies in centralization. Our power lies in decentralization. They consolidate; we distribute. They monopolize; we federate. They surveil; we encrypt. This is the way."</span> {/* Updated classes */}
              </div>
              <div className={styles.terminalLine}>&#125;</div> {/* Updated class */}
              <div className={styles.terminalLine}> {/* Updated class */}
                <span className={styles.highlightComment}># Execute the liberation program</span> {/* Updated class */}
              </div>
              <div className={`${styles.terminalLine} ${styles.commandLine}`}> {/* Updated classes */}
                declare_independence
              </div>
              <div className={`${styles.terminalLine} ${styles.commandLine}`}> {/* Updated classes */}
                reject_feudalism
              </div>
              <div className={`${styles.terminalLine} ${styles.commandLine}`}> {/* Updated classes */}
                distributed_resistance
              </div>
              <div className={styles.terminalLine}> {/* Updated class */}
                <span className={styles.highlightKeyword}>printf</span> <span className={styles.highlightString}>"Join the resistance. Reclaim your digital sovereignty. %s\n"</span> <span className={styles.highlightVariable}>$LIBERATION_CODE</span> {/* Updated classes */}
              </div>
              
              <div className={styles.terminalExecution}> {/* Updated class */}
                <div className={styles.executionIndicator}>Loading liberation sequence...</div> {/* Updated class */}
                <div className={styles.progressBar}> {/* Updated class */}
                  <div className={styles.progress}></div> {/* Updated class */}
                </div>
                <div className={styles.executionOutput}>Digital sovereignty status: <span className={styles.statusActive}>ACTIVE</span></div> {/* Updated classes */}
              </div>
            </div>
          </div>
          
          {/* Quotes Carousel */}
          {/* Removed Bootstrap Row/Col */}
          <div className={`${styles.quotesSection} ${styles.my5}`}> {/* Updated classes */}
            <div className={styles.quotesContainer}> {/* Added container div */}
              {quotes.map((quote, index) => (
                <div key={index} className={styles.quoteWrapper}> {/* Added wrapper div */}
                  <ManifestoQuote quote={quote.quote} author={quote.author} />
                </div>
              ))}
            </div>
          </div>
          
          {/* Against Digital Feudalism */}
          <div className={`${styles.feudalismSection} ${styles.my5}`}> {/* Updated classes */}
            <div className={styles.manifestoSectionTitle}> {/* Updated class */}
              <h2><span className={styles.sectionNumber}>02</span> Against Digital Feudalism</h2> {/* Updated class */}
            </div>
            
            <div className={`${styles.terminalBox} ${styles.warningTerminal}`}> {/* Updated classes */}
              <div className={`${styles.terminalHeader} ${styles.warning}`}> {/* Updated classes */}
                <div className={`${styles.terminalButton} ${styles.red}`}></div> {/* Updated classes */}
                <div className={`${styles.terminalButton} ${styles.yellow}`}></div> {/* Updated classes */}
                <div className={`${styles.terminalButton} ${styles.green}`}></div> {/* Updated classes */}
                <div className={styles.terminalTitle}>WARNING: digital_feudalism.log</div> {/* Updated class */}
              </div>
              <div className={styles.terminalBody}> {/* Updated class */}
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightWarning}>WARNING:</span> SaaS offerings that cannot be self-hosted create vendor lock-in {/* Updated class */}
                </div>
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightWarning}>WARNING:</span> Data in third-party systems faces sovereignty vulnerabilities {/* Updated class */}
                </div>
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightWarning}>WARNING:</span> Subscription models transfer wealth without transferring ownership {/* Updated class */}
                </div>
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightWarning}>WARNING:</span> Algorithmic governance replacing human decision-making {/* Updated class */}
                </div>
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightWarning}>WARNING:</span> Technical illiteracy creating new class of digital serfs {/* Updated class */}
                </div>
                <div className={styles.terminalLine}> {/* Updated class */}
                  <span className={styles.highlightError}>FATAL:</span> Democracy cannot survive when critical infrastructure is controlled by unaccountable entities {/* Updated class */}
                </div>
              </div>
            </div>
            
            <div className={`${styles.literaryProse} ${styles.mt4}`}> {/* Updated classes */}
              <div className={styles.proseWrapper}> {/* Updated class */}
                <p className={styles.proseContent}> {/* Updated class */}
                  My work is explicitly positioned in opposition to the emerging digital feudal order, where a small number of technology overlords control the digital infrastructure that powers our society. I refuse to be a vassal—and I refuse to let you become one either.
                </p>
              </div>
            </div>
          </div>
          
          {/* Closing Call to Action */}
          <div className={`${styles.systemAlert} ${styles.my5}`}> {/* Updated classes */}
            <div className={styles.alertHeader}> {/* Updated class */}
              <div className={styles.alertTitle}>JOIN THE RESISTANCE</div> {/* Updated class */}
            </div>
            <div className={styles.alertContent}> {/* Updated class */}
              <h3 className={styles.alertHeading}>DIGITAL LIBERATION BEGINS WITH A CHOICE</h3> {/* Updated class */}
              <p className={styles.alertMessage}> {/* Updated class */}
                The choice between convenience and sovereignty. Between short-term ease and long-term freedom. Between dependency and self-determination.
              </p>
              <a href="mailto:casey@samaritansolutions.net" className={styles.alertButton}> {/* Updated class */}
                MAKE CONTACT
              </a>
            </div>
            <div className={styles.alertDecoration}> {/* Updated class */}
              <div className={styles.alertLine}></div> {/* Updated class */}
              <div className={`${styles.alertDot} ${styles.top}`}></div> {/* Updated classes */}
              <div className={`${styles.alertDot} ${styles.bottom}`}></div> {/* Updated classes */}
            </div>
          </div>
        </div> {/* End inner container div */}
      </div> {/* End manifestoSection div */}
    </section>
  );
}

export default Manifesto;
