import React from "react";
import { FaTools, FaCode, FaShieldAlt, FaPalette, FaDesktop } from "react-icons/fa";

function TechnicalArsenal() {
  return (
    <div className="section">
      <h2 className="section-title">
        <FaTools /> Technical Arsenal
      </h2>
      <div className="skills-grid">
        <div className="skill-category">
          <h3 className="skill-category-title"><FaCode /> Development & Architecture</h3>
          <ul className="skill-list">
            <li className="skill-item">Full-Stack: HTML5/CSS3, jQuery, PHP</li>
            <li className="skill-item">Backend: NodeJS, Python/Flask</li>
            <li className="skill-item">API Integration & Development</li>
            <li className="skill-item">Enterprise Architecture Design</li>
          </ul>
        </div>

        <div className="skill-category">
          <h3 className="skill-category-title"><FaShieldAlt /> Security & Infrastructure</h3>
          <ul className="skill-list">
            <li className="skill-item">VMWare, Proxmox, QEMU</li>
            <li className="skill-item">Windows, Linux Server Administration</li>
            <li className="skill-item">Kubernetes Orchestration</li>
            <li className="skill-item">Enterprise System Hardening</li>
            <li className="skill-item">Zero-Trust Architecture</li>
          </ul>
        </div>

        <div className="skill-category">
          <h3 className="skill-category-title"><FaPalette /> Creative Solutions</h3>
          <ul className="skill-list">
            <li className="skill-item">Adobe Creative Cloud Suite</li>
            <li className="skill-item">macOS, Godot, Quest dev</li>
            <li className="skill-item">Digital Asset Management</li>
            <li className="skill-item">UI/UX Implementation</li>
            <li className="skill-item">Brand Identity Systems</li>
          </ul>
        </div>

        <div className="skill-category">
          <h3 className="skill-category-title"><FaDesktop /> Enterprise Systems</h3>
          <ul className="skill-list">
            <li className="skill-item">Advanced System Integration</li>
            <li className="skill-item">Cloud Infrastructure (Azure)</li>
            <li className="skill-item">IoT & Smart Device Security</li>
            <li className="skill-item">Custom Security Solutions</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TechnicalArsenal;
