import React from 'react';
import { Container } from "react-bootstrap";
import styles from './DigitalImpactSection.module.css'; // Import the CSS Module

// Component for the Digital Liberation Impact section
const DigitalImpactSection = () => {
  return (
    // Use module style for the section
    <Container fluid className={styles.digitalImpactSection}> 
      <Container>
        {/* Use module style for the title container */}
        <div className={styles.sectionTitle}> 
          {/* Apply global glitch class + module class */}
          <h2 className={`glitch-title ${styles.glitchTitle}`} data-text="DIGITAL LIBERATION IMPACT">DIGITAL LIBERATION IMPACT</h2>
          <div className={styles.consoleLine}>
            <span className={styles.consolePrompt}>user@liberation:~$</span> 
            <span className={styles.consoleCommand}>tail -f /var/log/freedom_metrics.log</span>
          </div>
        </div>
        
        {/* Use module style for the terminal */}
        <div className={styles.liberationTerminal}> 
          <div className={styles.terminalHeader}>
            <div className={`${styles.terminalButton} ${styles.red}`}></div>
            <div className={`${styles.terminalButton} ${styles.yellow}`}></div>
            <div className={`${styles.terminalButton} ${styles.green}`}></div>
            <div className={styles.terminalTitle}>freedom_metrics.log</div>
          </div>
          <div className={styles.terminalBody}>
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:47:32]</span> <span className={`${styles.logLevel} ${styles.info}`}>INFO</span> Analyzing digital dependency vectors...</div>
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:47:45]</span> <span className={`${styles.logLevel} ${styles.warn}`}>WARN</span> <span className={styles.logCritical}>Subscription dependency detected: $3,600/year + vendor lock-in</span></div>
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:48:01]</span> <span className={`${styles.logLevel} ${styles.info}`}>INFO</span> Initiating liberation protocol...</div>
            
            <div className={styles.comparisonGrid}>
              <div className={styles.comparisonColumn}>
                <div className={`${styles.comparisonHeader} ${styles.dependency}`}>DEPENDENCY CHAINS</div>
                <ul className={styles.dependencyList}>
                  <li><span className={styles.statusNegative}>✗</span> Proprietary software licensing fees</li>
                  <li><span className={styles.statusNegative}>✗</span> Cloud hosting recurring costs</li>
                  <li><span className={styles.statusNegative}>✗</span> Third-party service integration fees</li>
                  <li><span className={styles.statusNegative}>✗</span> Vendor-controlled update cycles</li>
                  <li><span className={styles.statusNegative}>✗</span> Support ticket response times</li>
                </ul>
              </div>
              
              <div className={styles.comparisonColumn}>
                <div className={`${styles.comparisonHeader} ${styles.freedom}`}>FREEDOM MATRIX</div>
                <ul className={styles.freedomList}>
                  <li><span className={styles.statusPositive}>✓</span> Complete infrastructure ownership</li>
                  <li><span className={styles.statusPositive}>✓</span> Self-hosted systems with fixed costs</li>
                  <li><span className={styles.statusPositive}>✓</span> Open-source software foundation</li>
                  <li><span className={styles.statusPositive}>✓</span> Self-determined update schedule</li>
                  <li><span className={styles.statusPositive}>✓</span> In-house knowledge and control</li>
                </ul>
              </div>
            </div>
            
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:49:57]</span> <span className={`${styles.logLevel} ${styles.info}`}>INFO</span> Liberation impact analysis complete</div>
            
            <div className={styles.metricsGrid}>
              <div className={styles.metricCard}>
                <div className={styles.metricValue}>63<span className={styles.metricPercent}>%</span></div>
                <div className={styles.metricLabel}>COST REDUCTION</div>
                <div className={styles.metricDetail}>healthcare provider</div>
              </div>
              <div className={styles.metricCard}>
                <div className={styles.metricValue}>4.5<span className={styles.metricUnit}>x</span></div>
                <div className={styles.metricLabel}>PERFORMANCE GAIN</div>
                <div className={styles.metricDetail}>e-commerce platform</div>
              </div>
              <div className={styles.metricCard}>
                <div className={styles.metricValue}>18<span className={styles.metricUnit}>hrs</span></div>
                <div className={styles.metricLabel}>TIME RECLAIMED</div>
                <div className={styles.metricDetail}>per week</div>
              </div>
            </div>
          
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:50:23]</span> <span className={`${styles.logLevel} ${styles.info}`}>INFO</span> Calculating ROI timeframe: <span className={styles.highlightText}>8-16 months</span></div>
            <div className={styles.terminalLine}><span className={styles.logTime}>[12:50:47]</span> <span className={`${styles.logLevel} ${styles.success}`}>SUCCESS</span> Digital sovereignty protocol ready for deployment</div>
            <div className={styles.blinkingCursor}></div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default DigitalImpactSection;
