import React from 'react';
import { Container, Row } from "react-bootstrap"; 
import { FaServer, FaShieldAlt, FaCode } from "react-icons/fa";
import PathwayCard from './PathwayCard'; 
import styles from './LiberationPathways.module.css'; // Import the CSS Module

// Component for the Liberation Pathways section
const LiberationPathways = () => {
  const pathways = [
    {
      icon: <FaServer />,
      title: "Infrastructure Liberation",
      description: "Breaking free from corporate cloud dependency through self-hosted, sovereign alternatives.",
      linkTo: "/liberation"
    },
    {
      icon: <FaShieldAlt />,
      title: "Data Sovereignty",
      description: "Reclaiming control over your digital identity and ensuring true ownership of your data.",
      linkTo: "/liberation"
    },
    {
      icon: <FaCode />,
      title: "Knowledge Transfer",
      description: "Breaking dependency cycles through comprehensive documentation and skill building.",
      linkTo: "/liberation"
    }
  ];

  return (
    // Use module style for the section
    <Container fluid className={styles.liberationSection} id="liberation-pathways"> 
      <Container>
        {/* Use module style for the title container */}
        <div className={styles.sectionTitle}> 
          {/* Apply global gradient class + module class */}
          <h2 className={`gradient-text ${styles.gradientText}`}> 
            Liberation <span className={`accent-text ${styles.accentText}`}>Pathways</span>
          </h2>
          {/* Use module style for the underline */}
          <div className={styles.titleUnderline}></div> 
        </div>
        
        {/* Keep Bootstrap Row for layout for now */}
        <Row className="justify-content-center"> 
          {pathways.map((pathway, index) => (
            <PathwayCard 
              key={index}
              icon={pathway.icon}
              title={pathway.title}
              description={pathway.description}
              linkTo={pathway.linkTo}
            />
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default LiberationPathways;
