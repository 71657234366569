import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaGithub,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaMapMarkerAlt,
  FaShieldAlt,
  FaLock
} from "react-icons/fa";
import { SiProtonmail, SiMatrix } from "react-icons/si";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  
  return (
    <footer className="footer-section">
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={4} md={6} className="mb-5 mb-lg-0">
              <div className="footer-info">
                <div className="footer-logo">
                  <FaShieldAlt className="footer-logo-icon" />
                  <h3 className="footer-title">Digital Resistance</h3>
                </div>
                <p className="footer-description">
                  From the shadows between Buffalo and Hamilton, I guide organizations toward technological autonomy—revealing the quiet tyranny of digital feudalism that has become our shared invisible prison.
                </p>
                <div className="footer-contact">
                  <div className="contact-item">
                    <FaEnvelope className="contact-icon" />
                    <a href="mailto:casey@samaritansolutions.net">casey@samaritansolutions.net</a>
                  </div>
                  <div className="contact-item">
                    <SiProtonmail className="contact-icon" />
                    <a href="mailto:fimeg@proton.me">fimeg@proton.me</a>
                  </div>
                  <div className="contact-item">
                    <FaMapMarkerAlt className="contact-icon" />
                    <span>Buffalo, NY & Hamilton, ON</span>
                  </div>
                </div>
              </div>
            </Col>
            
            <Col lg={4} md={6} className="mb-5 mb-lg-0">
              <div className="footer-nav">
                <h3 className="footer-title">Liberation Paths</h3>
                <ul className="footer-links">
                  <li>
                    <FaLock className="footer-link-icon" />
                    <Link to="/manifesto">Digital Liberation Manifesto</Link>
                  </li>
                  <li>
                    <FaLock className="footer-link-icon" />
                    <Link to="/liberation">Infrastructure Sovereignty</Link>
                  </li>
                  <li>
                    <FaLock className="footer-link-icon" />
                    <Link to="/liberation">Data Sovereignty</Link>
                  </li>
                  <li>
                    <FaLock className="footer-link-icon" />
                    <Link to="/arsenal">Liberation Arsenal</Link>
                  </li>
                  <li>
                    <FaLock className="footer-link-icon" />
                    <a href="https://samaritansolutions.net/contact" target="_blank" rel="noreferrer">Begin Your Liberation</a>
                  </li>
                </ul>
              </div>
            </Col>
            
            <Col lg={4} md={12}>
              <div className="footer-terminal">
                <div className="terminal-header">
                  <div className="terminal-button red"></div>
                  <div className="terminal-button yellow"></div>
                  <div className="terminal-button green"></div>
                  <div className="terminal-title">system_analysis.log</div>
                </div>
                <div className="terminal-body">
                  <div className="terminal-line">
                    <span className="prompt-user">root@abhorrent-truth</span>
                    <span className="prompt-separator">:</span>
                    <span className="prompt-directory">~/analysis</span>
                    <span className="prompt-symbol">$ </span>
                    <span className="prompt-command">cat digital_revelation.txt</span>
                  </div>
                  <div className="terminal-response">
                    "The most elegant deception of the digital age is the illusion that you are the user and not the product being consumed."
                  </div>
                  <div className="truth-snippet">
                    // What they sell as connection is merely surveillance with a friendly interface
                  </div>
                  <div className="terminal-line">
                    <span className="prompt-user">root@abhorrent-truth</span>
                    <span className="prompt-separator">:</span>
                    <span className="prompt-directory">~/analysis</span>
                    <span className="prompt-symbol">$ </span>
                    <span className="prompt-command">grep "paradigm" system_data</span>
                  </div>
                  <div className="terminal-response">
                    "The subscription economy exists not to serve you, but to ensure your dependency remains unbroken for their perpetual profit."
                  </div>
                  <div className="truth-snippet">
                    // Each new version erases your memory of freedom
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
      <div className="footer-bottom">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-start mb-3 mb-md-0">
              <div className="copyright">
                © {year} Digital Resistance | Architect: Casey Tunturi
              </div>
            </Col>
            <Col md={6} className="text-center text-md-end">
              <div className="social-links">
                <a href="https://github.com/Fimeg" target="_blank" rel="noreferrer">
                  <FaGithub />
                </a>
                <a href="https://www.linkedin.com/in/casey-tunturi-1b59a0183/" target="_blank" rel="noreferrer">
                  <FaLinkedinIn />
                </a>
                <a href="https://twitter.com/Fimeg" target="_blank" rel="noreferrer">
                  <FaTwitter />
                </a>
                <a href="https://matrix.to/#/@casey:matrix.org" target="_blank" rel="noreferrer">
                  <SiMatrix />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;