// main-site/bio/src/data/arsenalItems/mongodb.js
import React from 'react';
import { SiMongodb } from "react-icons/si";
import { FaDatabase, FaBook, FaGithub } from "react-icons/fa";

const mongodb = {
  id: "mongodb",
  name: "MongoDB",
  icon: <SiMongodb />,
  category: "storage",
  description: "Document-oriented NoSQL database designed for modern application development and scalability",
  sovereignty: 4,
  expertise: 4,
  useCases: [
    "Document storage and retrieval",
    "Real-time analytics applications",
    "Content management systems"
  ],
  // Additional details for expanded view
  detailedDescription: "MongoDB is a document-oriented NoSQL database designed for modern application development. It stores data in flexible, JSON-like documents, enabling developers to work with data in a way that naturally maps to objects in application code. MongoDB's architecture allows for horizontal scaling across multiple machines, providing high availability and performance.",
  advantages: [
    "Flexible schema design that adapts to changing application requirements",
    "Native support for geospatial data and complex queries",
    "Built-in sharding for horizontal scaling across clusters",
    "Rich indexing capabilities for optimized query performance",
    "Community Edition provides sovereignty with enterprise-grade capabilities"
  ],
  alternatives: [
    { name: "DynamoDB (AWS)", icon: <FaDatabase />, sovereignty: 1 },
    { name: "Cosmos DB (Microsoft)", icon: <FaDatabase />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://docs.mongodb.com/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/mongodb/mongo",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Import MongoDB public GPG key and create a source list file",
    "Install MongoDB Community Edition packages",
    "Configure mongod.conf for security, storage, and networking",
    "Set up authentication and secure database access",
    "Implement backup strategy with mongodump or filesystem snapshots"
  ]
};

export default mongodb;
