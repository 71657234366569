import React from "react";
import { FaCode } from "react-icons/fa";

function InnovationPortfolio() {
  return (
    <div className="section">
      <h2 className="section-title">
        <FaCode /> Innovation Portfolio
      </h2>
      <div className="experience-item">
        <h3 className="experience-title">Enterprise Solutions & Digital Transformation</h3>
        <ul>
          <li>Revolutionized content management with one-click audio processing system</li>
          <li>Engineered enterprise-grade monitoring and VPN infrastructure</li>
          <li>Developed scalable remote access and automation frameworks</li>
          <li>Created comprehensive knowledge management systems</li>
        </ul>
        <div className="project-links">
          <a href="https://fbcwaldport.org" target="_blank" rel="noopener noreferrer">First Baptist Church</a>
          <a href="https://kb.samaritansolutions.net" target="_blank" rel="noopener noreferrer">Knowledge Base</a>
          <a href="https://remotely.wiuf.net" target="_blank" rel="noopener noreferrer">Remote Platform</a>
        </div>
      </div>
    </div>
  );
}

export default InnovationPortfolio;
