import React from 'react';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './ManifestoPreview.module.css'; // Import the CSS Module

// Component for the Manifesto preview section
const ManifestoPreview = () => {
  return (
    // Use module styles for the section
    <Container fluid className={styles.manifestoSection}> 
      <Container>
        <div className={styles.literaryProse}>
          {/* Apply module style and potentially global CRT effect class */}
          <div className={`${styles.proseWrapper} ${styles.crtScreen} crt-screen`}> 
            <p className={styles.proseContent}>
              <span className={styles.proseDropcap}>"A</span>
              <span className={styles.proseEmphasis}>midst the</span> 
              {/* Apply module style and potentially global distortion effect class */}
              <span className={`${styles.proseHeading} digital-distortion`} data-text="ENCRYPTED WHISPERS">ENCRYPTED WHISPERS</span> 
              of digital realms, I stand as both 
              <span className={styles.proseSpaced}>a r c h i t e c t</span> and 
              <span className={styles.proseSpaced}>l i b e r a t o r</span>. 
              Not a vendor of services, but a 
              <span className={styles.proseStrikethrough}>guide</span> 
              <span className={styles.proseCommand}>chmod +x /usr/local/bin/sovereignty</span> 
              through the labyrinth of technological dependency. I craft not mere solutions, but 
              <span className={styles.proseItalic}>pathways to self-determination</span>.
              <br></br>In the 
              {/* Apply module style and potentially global corruption effect class */}
              <span className={`${styles.corruptedData} corrupted-data`}>s i l e n c e</span> 
              between processor cycles, a revolution forms."
            </p>
            
            {/* Apply module style and potentially global border effect class */}
            <Link to="/manifesto" className={`${styles.readMoreLink} cyber-border`}>
              READ FULL MANIFESTO <span className={styles.arrowRight}>→</span>
            </Link>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default ManifestoPreview;
