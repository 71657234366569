import React from "react";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaGlobe } from "react-icons/fa";
import pdf from "../../Assets/Resume2023.pdf";

function ResumeHeader() {
  return (
    <div className="resume-header">
      <h1 className="resume-title">Casey Tunturi</h1>
      <h2 className="resume-subtitle">Cybersecurity Architect & Digital Innovation Leader</h2>
      
      <div className="contact-info">
        <p><FaMapMarkerAlt /> Buffalo, New York, U.S.A</p>
        <p><FaEnvelope /> casey@samaritansolutions.net</p>
        <p><FaPhone /> (541) 283-6104</p>
        <p><FaGlobe /> bio.wiuf.net</p>
      </div>

      <Button className="download-btn" href={pdf} target="_blank">
        <AiOutlineDownload /> Download PDF Version
      </Button>
    </div>
  );
}

export default ResumeHeader;
