import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiProxmox,
  SiWindows,
  SiUbiquiti,
  SiNextcloud,
  SiWireguard,
  SiFedora,
  SiTraefikproxy,
  SiDocker,
  SiGrafana,
  SiElasticsearch,
  SiKubernetes,
  SiGithub,
  SiGitlab,
  SiAnsible,
} from "react-icons/si";
import { FaServer, FaShieldAlt, FaBinoculars, FaDatabase } from "react-icons/fa";
import { DiLinux } from "react-icons/di";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* Operating Systems */}
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFedora />
      </Col>

      {/* Virtualization & Infrastructure */}
      <Col xs={4} md={2} className="tech-icons">
        <SiProxmox />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTraefikproxy />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbiquiti />
      </Col>

      {/* Security & Monitoring */}
      <Col xs={4} md={2} className="tech-icons">
        <FaShieldAlt title="Wazuh" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWireguard />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaBinoculars title="WatchTower" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGrafana />
      </Col>

      {/* Storage & Services */}
      <Col xs={4} md={2} className="tech-icons">
        <SiNextcloud />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaDatabase title="Duplicati" />
      </Col>

      {/* DevOps & Infrastructure */}
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnsible />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGitlab />
      </Col>

      {/* Monitoring & Logging */}
      <Col xs={4} md={2} className="tech-icons">
        <SiElasticsearch />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaServer title="System Monitoring" />
      </Col>

      {/* Development Tools */}
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
      </Col>
    </Row>
  );
}

export default Toolstack;
