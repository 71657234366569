import React from "react";
import { FaGraduationCap } from "react-icons/fa";

function Education() {
  return (
    <div className="section">
      <h2 className="section-title">
        <FaGraduationCap /> Educational Foundation
      </h2>
      <div className="education-grid">
        <div className="education-item">
          <h3 className="education-item-title">Faith Evangelical College & Seminary</h3>
          <p className="education-item-degree">Bachelor of Arts</p>
          <p className="education-item-duration">June 2013 - March 2017</p>
          <a href="https://www.faithiu.edu/" 
             target="_blank" 
             rel="noopener noreferrer" 
             className="education-item-link">
            www.faithiu.edu
          </a>
        </div>

        <div className="education-item">
          <h3 className="education-item-title">Oregon Coast Community College</h3>
          <p className="education-item-degree">Associates</p>
          <p className="education-item-duration">January 2011 - December 2012</p>
          <a href="https://oregoncoast.edu/" 
             target="_blank" 
             rel="noopener noreferrer" 
             className="education-item-link">
            oregoncoast.edu
          </a>
        </div>
      </div>
    </div>
  );
}

export default Education;
