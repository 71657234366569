// main-site/bio/src/data/arsenalItems/wireguard.js
import React from 'react';
import { SiWireguard } from "react-icons/si";
import { FaNetworkWired, FaLock, FaExternalLinkAlt, FaGithub } from "react-icons/fa";

const wireguard = {
  id: "wireguard",
  name: "WireGuard",
  icon: <SiWireguard />,
  category: "network",
  description: "Modern, fast, and secure VPN protocol with state-of-the-art cryptography and minimal attack surface",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Secure site-to-site connectivity",
    "Remote access solutions",
    "Cross-border secure communications"
  ],
  // Additional details for expanded view
  detailedDescription: "WireGuard is a modern VPN protocol designed with simplicity and security as core principles. With only about 4,000 lines of code (compared to OpenVPN's 100,000+), it provides a smaller attack surface while delivering superior performance. It uses state-of-the-art cryptography with sensible defaults that make it both highly secure and easy to configure.",
  advantages: [
    "Exceptionally small, auditable codebase with minimal attack surface",
    "Significantly faster than OpenVPN and IPsec with lower latency",
    "Seamless roaming between networks without losing connections",
    "Simple configuration with cryptographic key management",
    "Now integrated into the Linux kernel for optimal performance"
  ],
  alternatives: [
    { name: "Cisco AnyConnect", icon: <FaNetworkWired />, sovereignty: 1 },
    { name: "OpenVPN (larger codebase)", icon: <FaLock />, sovereignty: 4 }
  ],
  resources: [
    {
      title: "Official Website",
      url: "https://www.wireguard.com/",
      icon: <FaExternalLinkAlt />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/WireGuard/wireguard-linux",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Install WireGuard using your distribution's package manager",
    "Generate public/private key pairs for server and clients",
    "Configure the WireGuard interface with IP settings and peer details",
    "Set up routing and firewall rules to allow traffic flow",
    "Enable the WireGuard interface with 'wg-quick up wg0'"
  ]
};

export default wireguard;
