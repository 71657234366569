import React from "react";
import { FaUserTie } from "react-icons/fa";
import face from "../../Assets/face.jpg";
import { Row, Col } from "react-bootstrap";

function ProfessionalSummary() {
  return (
    <div className="section">
      <h2 className="section-title">
        <FaUserTie /> Professional Summary
      </h2>
      <div className="experience-item">
        <Row className="align-items-center">
          <Col lg={8} md={7} style={{ paddingRight: '30px' }}>
            <div style={{ maxWidth: '600px' }}>
              <p style={{ fontSize: '1.15em', lineHeight: '1.8', marginBottom: '20px', color: 'rgba(255, 255, 255, 0.9)' }}>
              I solve big tech problems.
              </p>
              <p style={{ fontSize: '1.15em', lineHeight: '1.8', color: 'rgba(255, 255, 255, 0.9)' }}>
              I've assisted Snoop Dogg, Drew Barrymore, and others - most of the time I didn't recognize who it was until I was told. 
              </p>
              <p>My passion is tech. I stand firm on the principles of complete transparency - teaching knowledge is power, sharing our methods is free. </p>
            </div>
          </Col>
          <Col lg={4} md={5} className="d-flex justify-content-center">
            <div style={{
              maxWidth: '280px',
              width: '100%',
              borderRadius: '15px',
              overflow: 'hidden',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
              border: '1px solid rgba(0, 255, 255, 0.2)',
              background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.1), rgba(255, 0, 255, 0.05))',
              padding: '12px',
              transition: 'all 0.3s ease',
              cursor: 'pointer'
            }}
            onMouseEnter={e => {
              e.currentTarget.style.transform = 'translateY(-5px)';
              e.currentTarget.style.boxShadow = '0 15px 40px rgba(0, 0, 0, 0.3)';
              e.currentTarget.style.borderColor = 'rgba(0, 255, 255, 0.3)';
            }}
            onMouseLeave={e => {
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.2)';
              e.currentTarget.style.borderColor = 'rgba(0, 255, 255, 0.2)';
            }}>
              <div style={{
                width: '100%',
                paddingBottom: '100%',
                position: 'relative',
                borderRadius: '10px',
                overflow: 'hidden'
              }}>
                <img 
                  src={face} 
                  alt="Casey Tunturi" 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease'
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ProfessionalSummary;
