// main-site/bio/src/data/arsenalItems/postgres.js
import React from 'react';
import { SiPostgresql, SiMysql } from "react-icons/si";
import { FaDatabase, FaBook, FaGithub } from "react-icons/fa";

const postgres = {
  id: "postgres",
  name: "PostgreSQL",
  icon: <SiPostgresql />,
  category: "storage",
  description: "Advanced open-source relational database with enterprise features and unparalleled reliability",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Primary application database",
    "Data warehousing and analytics",
    "GIS applications with PostGIS extension"
  ],
  // Additional details for expanded view
  detailedDescription: "PostgreSQL is a powerful, open-source object-relational database system with over 30 years of active development. It has earned a strong reputation for reliability, feature robustness, and performance, making it the preferred choice for organizations seeking true data sovereignty.",
  advantages: [
    "ACID compliance guaranteeing data integrity and reliability",
    "Advanced features like table inheritance, multi-version concurrency control",
    "Rich ecosystem of extensions (PostGIS, TimescaleDB, etc.)",
    "Strong security features including SSL, row-level security",
    "Enterprise-class performance without the enterprise price tag"
  ],
  alternatives: [
    { name: "Oracle Database", icon: <FaDatabase />, sovereignty: 1 },
    { name: "Microsoft SQL Server", icon: <FaDatabase />, sovereignty: 1 },
    { name: "MySQL (Oracle-owned)", icon: <SiMysql />, sovereignty: 2 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://www.postgresql.org/docs/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/postgres/postgres",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Install PostgreSQL using your distribution's package manager",
    "Initialize the database cluster and configure authentication",
    "Create database users and set appropriate permissions",
    "Configure PostgreSQL for performance based on hardware resources",
    "Set up regular backup routines with pg_dump or similar tools"
  ]
};

export default postgres;
