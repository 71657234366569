import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Professional <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Key projects and implementations from my professional journey
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Enterprise Security Implementation"
              description="Led comprehensive security infrastructure deployment for major healthcare providers. Implemented advanced threat detection systems, established secure data protocols, and created robust disaster recovery solutions. Achieved 99.9% uptime while maintaining HIPAA compliance."
              ghLink="https://github.com/Fimeg"
              demoLink="https://moxfive.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="IT Infrastructure Modernization"
              description="Spearheaded complete IT infrastructure overhaul for multiple enterprise clients. Implemented cloud-based solutions, automated key processes, and established modern DevOps practices. Resulted in 40% reduction in operational costs and 60% improvement in deployment speed."
              ghLink="https://github.com/Fimeg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Business Technology Solutions"
              description="Developed and implemented custom technology solutions for diverse business needs. Created scalable architectures, integrated various systems, and established efficient workflows. Improved business process efficiency by 45% through strategic automation and optimization."
              ghLink="https://github.com/Fimeg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Network Security Enhancement"
              description="Designed and implemented comprehensive network security solutions for insurance providers. Established multi-layer security protocols, implemented advanced monitoring systems, and created incident response procedures. Reduced security incidents by 75% while maintaining operational efficiency."
              ghLink="https://github.com/Fimeg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Cloud Migration Strategy"
              description="Led strategic cloud migration projects for enterprise clients. Developed comprehensive migration plans, implemented hybrid cloud solutions, and established cloud security protocols. Achieved 30% cost reduction and 50% improvement in resource utilization."
              ghLink="https://github.com/Fimeg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="System Integration & Automation"
              description="Executed complex system integration projects across multiple platforms. Implemented automated workflows, established data synchronization protocols, and created efficient system communication channels. Reduced manual processes by 70% and improved data accuracy by 90%."
              ghLink="https://github.com/Fimeg"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
