import React, { useState, useEffect, useRef, useMemo } from 'react'; // Import hooks and useRef
// Removed unused Col import
import Typewriter from "typewriter-effect";
import TerminalPrompt from "../Terminal/TerminalPrompt";
// We might need styles from IdentityCard for the quote, let's import it temporarily
import styles from './TerminalDisplay.module.css'; // Import the CSS Module

// Component for the main terminal display section
// Added props back for quote integration
const TerminalDisplay = ({
  terminalInitialized,
  terminalReady,
  updateTerminalContent,
  currentStatementIndex, // Note: This prop is no longer directly used by Typewriter but needed for data
  sovereigntyStatements
}) => {
  const typewriterContainerRef = useRef(null); // Ref for the typewriter scrollable area
  const philosophyContainerRef = useRef(null); // Ref for the main broadcast container
  const typewriterEndRef = useRef(null); // Ref for the scroll target
  const [shuffledMessages, setShuffledMessages] = useState([]); // State for shuffled messages

  // Combine and shuffle messages only once or when statements change
  useEffect(() => {
    const combinedMessages = [
      // Reduced double line breaks to single
      "They asked not what humans need, but what keeps humans dependent...\n\nThe First Law of Digital Dependency:\nAny technology sufficiently ubiquitous is indistinguishable from infrastructure.\n\nWith each click of agreement, we sign away not just privacy, but sovereignty—\nforging our own digital manacles while celebrating the gleam of their polish.\n\nThe Algorithms write themselves now, optimizing not for human flourishing\nbut for their own continued existence.\n\nWhat we call 'convenience' is but a shadow on the cave wall—\na silhouette of what freedom might have been.\n\n'To whom does your data answer when it whispers in the digital night?'",
      // Reduced double line breaks
      "The first pillar of digital sovereignty:\nInfrastructure liberation through self-hosted data centers and infrastructure.\n\nThe second pillar:\nSecurity autonomy with transparent, auditable systems and zero-knowledge architectures.\n\nThe third pillar:\nDevelopment independence allowing infinite customization and adaptation.\n\nThe future belongs to those who control their technological destiny.\nIt belongs to those who OWN rather than RENT their digital existence.\n\nI architect systems for organizations seeking to break free from the cloud dependency cycle.",
      // Reduced double line breaks
      "The subscription model isn't innovation—it's digital feudalism with better marketing.\nWhen technology becomes a lifelong rental, we've abandoned ownership for convenience,\ntrading control for comfort.\nTemporary access is permanent dependency.\nTrue digital sovereignty requires code that belongs to you, data that answers to you,\nand infrastructure you command.\nNot possession for possession's sake, but control for the sake of agency and autonomy.",
      // Adjusted formatting for sovereignty statements
      ...(sovereigntyStatements || []).map(s => `// SYSTEM_BROADCAST [${new Date().toLocaleTimeString('en-US', { hour12: false })}]:\n"${s.text}"`)
    ];

    // Fisher-Yates (Knuth) Shuffle algorithm
    const shuffleArray = (array) => {
      let currentIndex = array.length, randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    };

    setShuffledMessages(shuffleArray([...combinedMessages])); // Shuffle a copy
  }, [sovereigntyStatements]);


  // Effect to handle auto-scrolling using MutationObserver
  useEffect(() => {
    const container = typewriterContainerRef.current; // The scrollable container
    if (!container) return;

    // Function to scroll the target into view
    const scrollToBottom = () => {
       // Check if the target element exists before scrolling
       typewriterEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" }); // Try 'nearest' block alignment
    };

    // Observe changes in the container's subtree
    const observer = new MutationObserver(scrollToBottom);
    observer.observe(container, {
      childList: true,
      subtree: true,
      characterData: true
    });

    // Initial scroll in case content already exists
    scrollToBottom();

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []); // Run only once on mount

  // Removed comment about Col wrapper
  return (
    // Use CSS Module class names directly on the root element
    <div className={styles.terminalBox}> {/* Removed home-header class */}
      <div className={styles.scanlinesOverlay}></div> {/* Added for scanlines */}

      <div className={styles.terminalHeader}>
          <div className={`${styles.terminalButton} ${styles.red}`}></div>
          <div className={`${styles.terminalButton} ${styles.yellow}`}></div>
          <div className={`${styles.terminalButton} ${styles.green}`}></div>
          <div className={styles.terminalTitle}>MNEMONIC://psychohistory-terminal</div>
          <div className={styles.accessLevel}>ACCESS_LEVEL: FOUNDATION</div>
        </div>

        <div className={styles.terminalBody}>
          <div className={styles.systemStatus}>
            <div>SYSTEM_STATUS: ACTIVE</div>
            <div className={styles.liberatedSystems}>LIBERATED_SYSTEMS: 842</div>
          </div>

          <div onClick={() => updateTerminalContent('whoami identity')}>
            {/* Pass module style if TerminalPrompt doesn't handle it */}
            <TerminalPrompt content="whoami" style={{ fontSize: "1.1rem", color: "#e95d6a" }} /> 
          </div>
          <div className={`${styles.terminalResponse} ${styles.whoamiResponse}`}>
            casey_tunturi
          </div>
          
          <div onClick={() => updateTerminalContent('identity sovereignty architect')}>
             {/* Pass module style if TerminalPrompt doesn't handle it */}
            <TerminalPrompt content="cat identity.txt" style={{ fontSize: "1.1rem", color: "#e95d6a" }} />
          </div>
          <div className={`${styles.terminalResponse} ${styles.identityResponse}`}>
            <div className={styles.highlightComment}># Digital Identity Declaration</div>
            Casey Tunturi: <span className={styles.highlightName}>Architect of Liberation</span>
            <br/>Focus: <span className={styles.highlightFocus}>Restoring technological ownership and agency</span>
            <br/>Operating from: <span className={styles.highlightLocation}>Buffalo, NY & Hamilton, ON</span>
          </div>

          {/* Removed Integrated Identity Info */}

          <div onClick={() => updateTerminalContent('digital philosophy sovereignty dependency')}>
             {/* Pass module style if TerminalPrompt doesn't handle it */}
            <TerminalPrompt content="./digital_philosophy.sh" style={{ fontSize: "1.1rem", color: "#e95d6a" }} />
          </div>

          {/* NEW Consolidated Philosophy & Broadcast Section */}
          {terminalInitialized && sovereigntyStatements && sovereigntyStatements.length > 0 && (
            // Attach the new ref to the main container div
            <div className={styles.philosophyBroadcast} ref={philosophyContainerRef}>
              {/* Optional Header */}
              {/* <div className={styles.broadcastHeader}>PHILOSOPHY_STREAM</div> */}
              {/* Keep ref on the inner container for scrolling */}
              <div className={styles.philosophyTypewriterContainer} ref={typewriterContainerRef}>
                {/* Restore onInit logic with proper chaining */}
                <Typewriter
                  onInit={(typewriter) => {
                    // Use the direct ref to the container
                    const container = philosophyContainerRef.current;
                    const timerContainer = container?.querySelector(`.${styles.philosophyTimerContainer}`); // Find timer container

                    // Chain all commands together upfront for the loop using SHUFFLED messages
                    shuffledMessages.forEach((message) => {
                      const isBroadcast = message.startsWith('// SYSTEM_BROADCAST');

                      typewriter
                        .callFunction(() => { // Add/remove class *before* typing each message in the loop
                          // Removed console logs
                          if (container) {
                            if (isBroadcast) {
                              container.classList.add(styles.isBroadcast);
                              timerContainer?.classList.add(styles.isBroadcastTimer);
                            } else {
                              container.classList.remove(styles.isBroadcast);
                              timerContainer?.classList.remove(styles.isBroadcastTimer);
                            }
                          }
                        })
                        .typeString(message)
                        .pauseFor(12000) // Pause for 12 seconds
                        .deleteAll(1);   // Delete quickly
                    });

                    // Start the typewriter after defining the full sequence
                    typewriter.start();
                  }}
                  options={{
                    delay: 30, // Keep slowed typing speed
                    loop: true, // Use the built-in loop for the pre-defined sequence
                    wrapperClassName: styles.philosophyTypewriterContent, // Use module style
                    cursorClassName: styles.philosophyTypewriterCursor // Use module style
                  }}
                />
                 {/* Empty div to act as scroll target */}
                <div ref={typewriterEndRef} />
              </div>
              {/* Timer/Indicator Container - Conditionally renders hourglass or broadcast indicator via CSS */}
              <div className={styles.philosophyTimerContainer}>
                 {/* Hourglass structure (always present, animation controlled by CSS) */}
                <div className={styles.philosophyHourglass}>
                  <div className={styles.hourglassSand}></div> {/* Element for sand animation */}
                  <div className={styles.hourglassBorder}></div>
                </div>
                 {/* Broadcast Indicator (always present, visibility/animation controlled by CSS) */}
                 <div className={styles.broadcastIndicator}></div>
              </div>
              {/* Optional Footer */}
              {/* <div className={styles.broadcastFooter}>END_STREAM</div> */}
            </div>
          )}

          {terminalReady && (
            <>
              <div onClick={() => updateTerminalContent('expertise infrastructure security development')}>
                 {/* Pass module style if TerminalPrompt doesn't handle it */}
                <TerminalPrompt content="ls -la expertise/" style={{ fontSize: "1.1rem", color: "#e95d6a" }} />
              </div>
              <div className={styles.expertiseResponse}>
                <div className={styles.gridDisplay}>
                  <div className={styles.gridItem}>
                    <div className={`${styles.gridItemHeader} ${styles.infraHeader}`}>infrastructure-liberation/</div>
                    <ul className={styles.gridItemList}>
                      <li className={styles.gridItemListItem}><span className={styles.arrowInfra}>→</span> self-hosted-cloud.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowInfra}>→</span> proxmox-virtualization.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowInfra}>→</span> zero-trust-architecture.sys</li>
                    </ul>
                  </div>
                  
                  <div className={styles.gridItem}>
                    <div className={`${styles.gridItemHeader} ${styles.securityHeader}`}>security-sovereignty/</div>
                    <ul className={styles.gridItemList}>
                      <li className={styles.gridItemListItem}><span className={styles.arrowSecurity}>→</span> data-sovereignty.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowSecurity}>→</span> zero-knowledge-systems.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowSecurity}>→</span> post-quantum-crypto.sys</li>
                    </ul>
                  </div>
                  
                  <div className={styles.gridItem}>
                    <div className={`${styles.gridItemHeader} ${styles.devHeader}`}>development-automation/</div>
                    <ul className={styles.gridItemList}>
                      <li className={styles.gridItemListItem}><span className={styles.arrowDev}>→</span> custom-software.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowDev}>→</span> process-automation.sys</li>
                      <li className={styles.gridItemListItem}><span className={styles.arrowDev}>→</span> self-hosted-ai.sys</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Removed old System Broadcast Section */}
        </div>
      </div>
  );
};

export default TerminalDisplay;
