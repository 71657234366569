// main-site/bio/src/data/arsenalItems/matrix.js
import React from 'react';
import { SiMatrix, SiSlack, SiDiscord } from "react-icons/si"; // Added icons
import { FaBook, FaGithub, FaComments } from "react-icons/fa"; // Added icons

const matrix = {
  id: "matrix",
  name: "Matrix Protocol",
  icon: <SiMatrix />,
  category: "security",
  description: "Open standard for secure, decentralized, real-time communication with end-to-end encryption",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Secure organizational communications",
    "Encrypted team collaboration",
    "Vendor-independent messaging infrastructure"
  ],
  // Additional details for expanded view
  detailedDescription: "Matrix is an open standard and communication protocol for real-time communication. It is designed to allow users with accounts at one communications service provider to communicate with users of a different service provider via online chat, Voice over IP, and video telephony. It aims to be a decentralized, secure, and interoperable alternative to proprietary communication platforms.",
  advantages: [
    "Decentralized architecture prevents single points of control or failure",
    "End-to-end encryption (E2EE) by default for private chats and optionally for rooms",
    "Interoperability through bridging to other platforms (Slack, Discord, IRC, etc.)",
    "Open standard allows for diverse clients and server implementations (e.g., Element client, Synapse server)",
    "Self-hostable for maximum data sovereignty and control"
  ],
  alternatives: [
    { name: "Slack", icon: <SiSlack />, sovereignty: 1 },
    { name: "Discord", icon: <SiDiscord />, sovereignty: 1 },
    { name: "Microsoft Teams", icon: <FaComments />, sovereignty: 1 },
    { name: "XMPP (Jabber)", icon: <FaComments />, sovereignty: 5 }
  ],
  resources: [
    {
      title: "Matrix.org Website",
      url: "https://matrix.org/",
      icon: <FaBook />
    },
    {
      title: "Synapse Server (Reference Implementation)",
      url: "https://github.com/matrix-org/synapse",
      icon: <FaGithub />
    },
    {
      title: "Element Client (Popular Client)",
      url: "https://element.io/",
      icon: <FaComments />
    }
  ],
  installationSteps: [
    "Choose a Matrix homeserver implementation (e.g., Synapse, Dendrite, Conduit)",
    "Follow the installation guide for the chosen server (often involves Docker or package managers)",
    "Configure domain name, TLS certificates, and potentially federation settings",
    "Set up a reverse proxy (like NGINX) for handling client connections",
    "Choose and configure a Matrix client (e.g., Element Web/Desktop/Mobile)",
    "Register users and start communicating"
  ]
};

export default matrix;
