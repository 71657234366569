import React, { useState, useEffect } from "react";
import Particle from "../Particle";
import styles from "./ArsenalDisplay.module.css"; // Import CSS Module
// Import data from external file
import { arsenalItems, categories } from "../../data/arsenalContent.js"; 
// Import only necessary icons used directly in the component
import { FaTimes, FaChevronRight, FaSearch, FaUserShield, FaShieldAlt, FaExternalLinkAlt, FaTools, FaBook } from "react-icons/fa"; 
import { Link } from "react-router-dom";

function ArsenalDisplay() {
  const [activeCategory, setActiveCategory] = useState("all");
  const [expandedItem, setExpandedItem] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  
  // Animation effect on category change
  useEffect(() => {
    // Reset expanded item when category changes
    setExpandedItem(null);
  }, [activeCategory]);
  
  // Data is now imported from ../../data/arsenalContent.js
  
  // Filter arsenal items by category and search text
  const filteredArsenal = arsenalItems
    .filter(item => activeCategory === "all" || item.category === activeCategory)
    .filter(item => {
      if (!filterText) return true;
      const searchTerm = filterText.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm) ||
        item.category.toLowerCase().includes(searchTerm)
      );
    });
    
  // Handle expanding/collapsing item details
  const toggleItemDetails = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };
  
  // Toggle search bar visibility
  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (!showSearch) {
      // Focus the search input when shown
      setTimeout(() => {
        const searchInput = document.getElementById('arsenal-search');
        if (searchInput) searchInput.focus();
      }, 100);
    } else {
      // Clear search when hiding
      setFilterText("");
    }
  };

  return (
    <section className={styles.arsenalPage}> {/* Use styles */}
      <Particle />
      {/* Replace Container fluid */}
      <div className={`${styles.arsenalSection} container-fluid`}> {/* Add Bootstrap class placeholder */}
        {/* Replace Container */}
        <div className="container"> {/* Add Bootstrap class placeholder */}
          {/* Header - Enhanced with cosmic elements */}
          <div className={`${styles.pageHeader} ${styles.cosmicHeader}`}> {/* Use styles */}
            <div className={`${styles.constellation} ${styles.constellationLeft}`}></div> {/* Use styles */}
            <div className={`${styles.constellation} ${styles.constellationRight}`}></div> {/* Use styles */}
            
            <div className={styles.cosmicGlow}></div> {/* Use styles */}
            <h1 className={styles.gradientText}>Liberation <span className={styles.accentText}>Arsenal</span></h1> {/* Use styles */}
            <div className={styles.titleDecoration}> {/* Use styles */}
              <div className={`${styles.decorationLine} ${styles.pulseLine}`}></div> {/* Use styles */}
              <div className={styles.decorationIcon}> {/* Use styles */}
                {/* Using FaShieldAlt directly as it's used in categories data */}
                <FaShieldAlt className={styles.rotatingIcon} /> {/* Use styles */}
              </div>
              <div className={`${styles.decorationLine} ${styles.pulseLine}`}></div> {/* Use styles */}
            </div>
            <p className={styles.headerDescription}> {/* Use styles */}
              Tools and technologies that form the foundation of digital sovereignty efforts.
              <span className={styles.subtextReveal}>Weapons of mass liberation in the war against digital feudalism.</span> {/* Use styles */}
            </p>
          </div>
          
          {/* Filter Controls */}
          <div className={styles.filterControls}> {/* Use styles */}
            {/* Search Toggle Button */}
            <button 
              className={`${styles.searchToggle} ${showSearch ? styles.active : ''}`} // Use styles
              onClick={toggleSearch}
              aria-label="Toggle search"
            >
              <FaSearch />
              {showSearch ? 'Hide Search' : 'Search Arsenal'}
            </button>
            
            {/* Search Bar */}
            {showSearch && (
              <div className={styles.searchContainer}> {/* Use styles */}
                <input
                  id="arsenal-search"
                  type="text"
                  className={styles.arsenalSearch} // Use styles
                  placeholder="Search tools, technologies, or categories..."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
                {filterText && (
                  <button 
                    className={styles.clearSearch} // Use styles
                    onClick={() => setFilterText('')}
                    aria-label="Clear search"
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            )}
          </div>
          
          {/* Arsenal Categories */}
          <div className={styles.categoryFilter}> {/* Use styles */}
            {categories.map(category => (
              <button
                key={category.id}
                className={`${styles.categoryButton} ${activeCategory === category.id ? styles.active : ''}`} // Use styles
                onClick={() => setActiveCategory(category.id)}
              >
                {/* Icon is now part of the imported categories data */}
                <span className={styles.categoryIcon}>{category.icon}</span> {/* Use styles */}
                <span className={styles.categoryName}>{category.name}</span> {/* Use styles */}
              </button>
            ))}
          </div>
          
          {/* Search Results */}
          {filterText && (
            <div className={styles.searchResults}> {/* Use styles */}
              <div className={styles.resultsCount}> {/* Use styles */}
                Found {filteredArsenal.length} item{filteredArsenal.length !== 1 ? 's' : ''} matching "{filterText}"
              </div>
            </div>
          )}
          
          {/* Arsenal Grid */}
          {/* Replace Row */}
          <div className={`${styles.arsenalGrid} row`}> {/* Add Bootstrap class placeholder */}
            {filteredArsenal.length > 0 ? (
              filteredArsenal.map(item => (
                // Replace Col
                <div className="col-lg-6 mb-4" key={item.id}> {/* Add Bootstrap class placeholder */}
                  <div className={`${styles.arsenalItem} ${expandedItem === item.id ? styles.expanded : ''}`}> {/* Use styles */}
                    <div className={styles.arsenalHeader}> {/* Use styles */}
                      {/* Icon is now part of the imported arsenalItems data */}
                      <div className={styles.arsenalIcon}>{item.icon}</div> {/* Use styles */}
                      <div className={styles.arsenalTitle}> {/* Use styles */}
                        <h3>{item.name}</h3>
                        {/* Find category name using imported categories data */}
                        <div className={styles.arsenalCategory}>{categories.find(c => c.id === item.category)?.name}</div> {/* Use styles */}
                      </div>
                    </div>
                    
                    <p className={styles.arsenalDescription}>{item.description}</p> {/* Use styles */}
                    
                    <div className={styles.arsenalMetrics}> {/* Use styles */}
                      <div className={styles.metric}> {/* Use styles */}
                        <div className={styles.metricLabel}>Sovereignty Score</div> {/* Use styles */}
                        <div className={styles.metricValue}> {/* Use styles */}
                          {Array(5).fill(0).map((_, i) => (
                            <span 
                              key={i} 
                              className={`${styles.metricPoint} ${i < item.sovereignty ? styles.active : ''}`} // Use styles
                            ></span>
                          ))}
                        </div>
                      </div>
                      
                      <div className={styles.metric}> {/* Use styles */}
                        <div className={styles.metricLabel}>Expertise Level</div> {/* Use styles */}
                        <div className={styles.metricValue}> {/* Use styles */}
                          {Array(5).fill(0).map((_, i) => (
                            <span 
                              key={i} 
                              className={`${styles.metricPoint} ${i < item.expertise ? styles.active : ''}`} // Use styles
                            ></span>
                          ))}
                        </div>
                      </div>
                    </div>
                    
                    <div className={styles.arsenalUses}> {/* Use styles */}
                      <div className={styles.usesLabel}>Example Liberation Use Cases:</div> {/* Use styles */}
                      <ul className={styles.usesList}> {/* Use styles */}
                        {item.useCases.map((use, i) => (
                          <li key={i}>{use}</li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={styles.arsenalLearnMore}> {/* Use styles */}
                      <button 
                        className={styles.learnMoreButton} // Use styles
                        onClick={() => toggleItemDetails(item.id)}
                      >
                        Learn More <FaChevronRight />
                      </button>
                    </div>
                    
                    {/* Expanded Item Details */}
                    {/* Check if detailedDescription exists in the imported item data */}
                    {item.detailedDescription && ( 
                      <div className={styles.arsenalItemDetails}> {/* Use styles */}
                        <button 
                          className={styles.detailsClose} // Use styles
                          onClick={() => setExpandedItem(null)}
                          aria-label="Close details"
                        >
                          <FaTimes />
                        </button>
                        
                        <div className={styles.detailsHeader}> {/* Use styles */}
                          {/* Icon is now part of the imported arsenalItems data */}
                          <div className={styles.detailsIcon}>{item.icon}</div> {/* Use styles */}
                          <div className={styles.detailsTitle}> {/* Use styles */}
                            <h3>{item.name}</h3>
                            {/* Find category name using imported categories data */}
                            <div className={styles.arsenalCategory}>{categories.find(c => c.id === item.category)?.name}</div> {/* Use styles */}
                          </div>
                        </div>
                        
                        <div className={styles.detailsSection}> {/* Use styles */}
                          <div className={styles.detailsSectionTitle}> {/* Use styles */}
                            <FaUserShield /> Sovereignty Profile
                          </div>
                          <p className={styles.detailsDescription}> {/* Use styles */}
                            {item.detailedDescription || item.description}
                          </p>
                        </div>
                        
                        {item.advantages && (
                          <div className={styles.detailsSection}> {/* Use styles */}
                            <div className={styles.detailsSectionTitle}> {/* Use styles */}
                              <FaShieldAlt /> Liberation Advantages
                            </div>
                            <ul className={styles.detailsList}> {/* Use styles */}
                              {item.advantages.map((advantage, i) => (
                                <li key={i}>{advantage}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        
                        {item.alternatives && (
                          <div className={styles.detailsSection}> {/* Use styles */}
                            <div className={styles.detailsSectionTitle}> {/* Use styles */}
                              <FaExternalLinkAlt /> Proprietary Alternatives
                            </div>
                            <div className={styles.detailsAlternatives}> {/* Use styles */}
                              <div className={styles.alternativesHeader}>Replaced by this sovereign solution:</div> {/* Use styles */}
                              <div className={styles.alternativesList}> {/* Use styles */}
                                {item.alternatives.map((alt, i) => (
                                  <div className={styles.alternativeItem} key={i}> {/* Use styles */}
                                    {/* Icon is now part of the imported arsenalItems data */}
                                    {alt.icon} {alt.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        
                        {item.installationSteps && (
                          <div className={styles.detailsSection}> {/* Use styles */}
                            <div className={styles.detailsSectionTitle}> {/* Use styles */}
                              <FaTools /> Deployment Notes
                            </div>
                            <ol className={styles.detailsList}> {/* Use styles */}
                              {item.installationSteps.map((step, i) => (
                                <li key={i}>{step}</li>
                              ))}
                            </ol>
                          </div>
                        )}
                        
                        {item.resources && (
                          <div className={styles.detailsSection}> {/* Use styles */}
                            <div className={styles.detailsSectionTitle}> {/* Use styles */}
                              <FaBook /> Resources
                            </div>
                            <div className={styles.detailsResources}> {/* Use styles */}
                              {item.resources.map((resource, i) => (
                                <a 
                                  key={i}
                                  href={resource.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.resourceLink} // Use styles
                                >
                                  {/* Icon is now part of the imported arsenalItems data */}
                                  {resource.icon} {resource.title}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              // Replace Col
              <div className="col-xs-12 text-center py-5"> {/* Add Bootstrap class placeholder */}
                <div className={styles.noResults}> {/* Use styles */}
                  <FaSearch className={styles.noResultsIcon} /> {/* Use styles */}
                  <h3>No matching tools found</h3>
                  <p>Try adjusting your search or category filter</p>
                  <button 
                    className={styles.resetFiltersButton} // Use styles
                    onClick={() => {
                      setFilterText('');
                      setActiveCategory('all');
                    }}
                  >
                    Reset Filters
                  </button>
                </div>
              </div>
            )}
          </div> {/* End replaced Row */}
          
          {/* Custom Solutions Note */}
          <div className={`${styles.customSolutions} my-5`}> {/* Use styles, Add Bootstrap class placeholder */}
            <div className={styles.terminalBox}> {/* Use styles */}
              <div className={styles.terminalHeader}> {/* Use styles */}
                <div className={`${styles.terminalButton} ${styles.red}`}></div> {/* Use styles */}
                <div className={`${styles.terminalButton} ${styles.yellow}`}></div> {/* Use styles */}
                <div className={`${styles.terminalButton} ${styles.green}`}></div> {/* Use styles */}
                <div className={styles.terminalTitle}>custom_solutions.sh</div> {/* Use styles */}
              </div>
              <div className={styles.terminalBody}> {/* Use styles */}
                <div className={styles.highlightComment}># Beyond the standard arsenal...</div> {/* Use styles */}
                <div className={styles.terminalLine}> {/* Use styles */}
                  <span className={styles.highlightKeyword}>echo</span> <span className={styles.highlightString}>"While these tools form the foundation of digital liberation, each organization's path to sovereignty requires strategic customization."</span> {/* Use styles */}
                </div>
                <div className={styles.terminalLine}> {/* Use styles */}
                  <span className={styles.highlightKeyword}>function</span> <span className={styles.highlightFunction}>custom_approach</span>() &#123; {/* Use styles */}
                </div>
                <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Use styles */}
                  <span className={styles.highlightComment}># Assessment of unique needs</span> {/* Use styles */}
                </div>
                <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Use styles */}
                  <span className={styles.highlightComment}># Strategic tool selection</span> {/* Use styles */}
                </div>
                <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Use styles */}
                  <span className={styles.highlightComment}># Custom integration and deployment</span> {/* Use styles */}
                </div>
                <div className={`${styles.terminalLine} ${styles.indent}`}> {/* Use styles */}
                  <span className={styles.highlightComment}># Knowledge transfer for autonomy</span> {/* Use styles */}
                </div>
                <div className={styles.terminalLine}>&#125;</div> {/* Use styles */}
              </div>
            </div>
          </div>
          
          {/* Call to Action */}
          <div className={`${styles.systemAlert} my-5`}> {/* Use styles, Add Bootstrap class placeholder */}
            <div className={styles.alertHeader}> {/* Use styles */}
              <div className={styles.alertTitle}>ARSENAL DEPLOYMENT</div> {/* Use styles */}
            </div>
            <div className={styles.alertContent}> {/* Use styles */}
              <h3 className={styles.alertHeading}>READY TO DEPLOY YOUR LIBERATION ARSENAL?</h3> {/* Use styles */}
              <p className={styles.alertMessage}> {/* Use styles */}
                Each tool represents a tactical advantage in your quest for digital sovereignty. Let's build your strategic implementation plan.
              </p>
              <Link to="/contact" className={styles.alertButton}> {/* Use styles */}
                INITIATE CONSULTATION
              </Link>
            </div>
            <div className={styles.alertDecoration}> {/* Use styles */}
              <div className={styles.alertLine}></div> {/* Use styles */}
              <div className={`${styles.alertDot} ${styles.top}`}></div> {/* Use styles */}
              <div className={`${styles.alertDot} ${styles.bottom}`}></div> {/* Use styles */}
            </div>
          </div>
        </div> {/* End replaced Container */}
      </div> {/* End replaced Container fluid */}
    </section>
  );
}

export default ArsenalDisplay;
