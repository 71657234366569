import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FaShieldAlt, FaBook, FaCode, FaUser, FaLock, FaGithub } from "react-icons/fa";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky digital-navbar" : "navbar digital-navbar"}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <FaShieldAlt className="brand-icon mr-2" />
          <span className="brand-text">DIGITAL<span className="d-none d-sm-inline"> RESISTANCE</span></span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <FaUser className="nav-icon" /> Casey Tunturi
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/manifesto"
                onClick={() => updateExpanded(false)}
              >
                <FaBook className="nav-icon" /> Manifesto
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/liberation"
                onClick={() => updateExpanded(false)}
              >
                <FaLock className="nav-icon" /> Liberation
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/arsenal"
                onClick={() => updateExpanded(false)}
              >
                <FaCode className="nav-icon" /> Arsenal
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href="https://github.com/Fimeg"
                target="_blank"
                rel="noreferrer"
                onClick={() => updateExpanded(false)}
              >
                <FaGithub className="nav-icon" /> GitHub
              </Nav.Link>
            </Nav.Item>
            
            <Nav.Item className="cta-btn">
              <Nav.Link
                href="https://samaritansolutions.net/contact"
                target="_blank"
                rel="noreferrer"
                className="cta-link"
                onClick={() => updateExpanded(false)}
              >
                Begin Liberation
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;