// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Assets/pre.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purple { 
  color: var(--imp-text-color) !important; 
}

button:focus { 
  box-shadow: none !important; 
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none { 
  opacity: 0; 
}

#no-scroll { 
  overflow: hidden; 
  height: 100vh; 
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #2d1950;
}

::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
}

/* Common utility classes */
.blockquote-footer {
  color: #a588c0 !important;
}

.icon-colour {
  color: #700c86 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base.css"],"names":[],"mappings":"AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA,cAAc;AACd;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,yDAAwC;EACxC,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA,cAAc;AACd;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;AACxC;;AAEA,2BAA2B;AAC3B;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@import './variables.css';\n\n.purple { \n  color: var(--imp-text-color) !important; \n}\n\nbutton:focus { \n  box-shadow: none !important; \n}\n\n/* Preloader */\n#preloader {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 999999;\n  background-color: #0c0513;\n  background-image: url(../Assets/pre.svg);\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n#preloader-none { \n  opacity: 0; \n}\n\n#no-scroll { \n  overflow: hidden; \n  height: 100vh; \n}\n\n/* Scrollbar */\n::-webkit-scrollbar {\n  width: 7px;\n}\n\n::-webkit-scrollbar-track {\n  background: #2d1950;\n}\n\n::-webkit-scrollbar-thumb {\n  background: rgba(178, 121, 216, 0.959);\n  border-radius: 12px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: rgba(222, 130, 235, 0.911);\n}\n\n/* Common utility classes */\n.blockquote-footer {\n  color: #a588c0 !important;\n}\n\n.icon-colour {\n  color: #700c86 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
