import React from 'react';
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './PathwayCard.module.css'; // Import the CSS Module

// Component for individual Liberation Pathway cards
const PathwayCard = ({ icon, title, description, linkTo }) => {
  return (
    // Apply the module class name to the Col for consistent spacing if needed, or remove Col later
    <Col md={4} className="mb-4"> 
      {/* Use the CSS module class names */}
      <div className={styles.pathwayCard}> 
        <div className={styles.pathwayIcon}>
          {icon} 
        </div>
        <h3 className={styles.pathwayTitle}>{title}</h3>
        <p className={styles.pathwayDescription}>
          {description}
        </p>
        <Link to={linkTo} className={styles.pathwayLink}>
          Explore Pathway <span className={styles.arrowRight}>→</span>
        </Link>
      </div>
    </Col>
  );
};

export default PathwayCard;
