// main-site/bio/src/data/arsenalItems/pfsense.js
import React from 'react';
import { SiPfsense } from "react-icons/si";
import { FaBook, FaExternalLinkAlt, FaNetworkWired } from "react-icons/fa"; // Added icons

const pfsense = {
  id: "pfsense",
  name: "pfSense",
  icon: <SiPfsense />,
  category: "network",
  description: "Open-source firewall and router platform based on FreeBSD with enterprise-grade networking capabilities",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Network edge security",
    "Advanced routing and VLANs",
    "Multi-WAN failover configurations"
  ],
  // Additional details for expanded view
  detailedDescription: "pfSense is a widely trusted open-source firewall and router distribution based on FreeBSD. It offers a comprehensive set of features typically found only in expensive commercial firewalls, making it suitable for securing networks of all sizes, from home labs to large enterprises.",
  advantages: [
    "Completely open-source with no licensing fees or artificial limitations",
    "Extensive feature set including VPN, load balancing, traffic shaping, and captive portal",
    "Highly configurable through a user-friendly web interface",
    "Strong community support and extensive documentation",
    "Regular updates and security patches from Netgate"
  ],
  alternatives: [
    { name: "OPNsense (Fork of pfSense)", icon: <FaNetworkWired />, sovereignty: 5 },
    { name: "Cisco ASA", icon: <FaNetworkWired />, sovereignty: 1 },
    { name: "Palo Alto Networks", icon: <FaNetworkWired />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://docs.netgate.com/pfsense/en/latest/",
      icon: <FaBook />
    },
    {
      title: "Netgate Website",
      url: "https://www.pfsense.org/",
      icon: <FaExternalLinkAlt />
    }
  ],
  installationSteps: [
    "Download the appropriate pfSense installer image (ISO or USB memstick)",
    "Write the image to installation media (USB drive or CD/DVD)",
    "Boot the target hardware from the installation media",
    "Follow the on-screen installation prompts (accept defaults for most)",
    "Assign network interfaces (WAN, LAN) during initial setup",
    "Access the web interface from the LAN side to complete configuration"
  ]
};

export default pfsense;
