import React from "react";

function TerminalPrompt({ content, typing = false }) {
  return (
    <div className="terminal-prompt">
      <span className="prompt-user">resistance@digital-sovereignty</span>
      <span className="prompt-separator">:</span>
      <span className="prompt-directory">~/liberation</span>
      <span className="prompt-symbol">$ </span>
      <span className={`prompt-command ${typing ? "typing" : ""}`}>{content}</span>
    </div>
  );
}

export default TerminalPrompt;