// main-site/bio/src/data/arsenalItems/nginx.js
import React from 'react';
import { SiNginx, SiApache } from "react-icons/si";
import { FaServer, FaBook, FaGithub } from "react-icons/fa";

const nginx = {
  id: "nginx",
  name: "NGINX",
  icon: <SiNginx />,
  category: "infrastructure",
  description: "High performance web server, reverse proxy, and load balancer for building self-hosted infrastructure",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Web application hosting and proxy",
    "API gateway with rate limiting",
    "TLS termination and security layer"
  ],
  // Additional details for expanded view
  detailedDescription: "NGINX is an open-source, high-performance HTTP server, reverse proxy, and load balancer. Its event-driven architecture allows it to handle thousands of concurrent connections with minimal resource consumption, making it ideal for high-traffic websites and microservice architectures.",
  advantages: [
    "Superior performance for static content and concurrent connections",
    "Highly configurable with a modular design",
    "Excellent for microservices architecture as an API gateway",
    "Built-in load balancing capabilities for horizontal scaling",
    "Battle-tested security features including rate limiting and request filtering"
  ],
  alternatives: [
    { name: "Apache HTTP Server", icon: <SiApache />, sovereignty: 4 },
    { name: "Microsoft IIS", icon: <FaServer />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://nginx.org/en/docs/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/nginx/nginx",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Install NGINX using your distribution's package manager (apt, yum, etc.)",
    "Configure server blocks in /etc/nginx/sites-available/",
    "Create symbolic links to sites-enabled to activate configurations",
    "Test configuration with nginx -t",
    "Reload or restart NGINX service to apply changes"
  ]
};

export default nginx;
