import React from "react";
import styles from "./ManifestoQuote.module.css"; // Import CSS Module

function ManifestoQuote({ quote, author }) {
  return (
    <div className={styles.manifestoQuote}> {/* Updated class */}
      <div className={styles.quoteContent}> {/* Updated class */}
        <span className={`${styles.quoteMark} ${styles.open}`}>"</span> {/* Updated classes */}
        {quote}
        <span className={`${styles.quoteMark} ${styles.close}`}>"</span> {/* Updated classes */}
      </div>
      <div className={styles.quoteAuthor}>— {author}</div> {/* Updated class */}
    </div>
  );
}

export default ManifestoQuote;
