import React from 'react';
import { Container } from "react-bootstrap";
import styles from './CtaSection.module.css'; // Import the CSS Module

// Component for the Call to Action section
const CtaSection = ({ updateTerminalContent }) => {
  // Note: updateTerminalContent prop might be needed by the button
  return (
    // Use module style for the section
    <Container fluid className={styles.ctaSection}> 
      <Container>
        {/* Use module styles for the alert box */}
        <div className={styles.systemAlert}> 
          <div className={styles.alertHeader}>
             {/* Apply global glitch class + module class */}
            <div className={`glitch-text ${styles.alertTitle}`} data-text="SYSTEMS ARCHITECT">SYSTEMS ARCHITECT</div>
          </div>
          <div className={styles.alertContent}>
            <h3 className={styles.alertHeading}>SPECIALIST IN DIGITAL AUTONOMY</h3>
            <p className={styles.alertMessage}>
              Building self-sovereign technology infrastructure that your organization owns, controls, and understands. Infrastructure as a liberation strategy, not just a service.
            </p>
            <a 
              href="https://samaritansolutions.net/contact" 
              className={styles.alertButton} // Use module style
              onClick={() => updateTerminalContent('dependency audit digital autonomy')} // Pass the prop down
            >
              GET A FREE DEPENDENCY AUDIT
            </a>
          </div>
          <div className={styles.alertDecoration}>
            <div className={styles.alertLine}></div>
            <div className={`${styles.alertDot} ${styles.top}`}></div>
            <div className={`${styles.alertDot} ${styles.bottom}`}></div>
          </div>
          <div className={styles.dataStream}></div>
        </div>
      </Container>
    </Container>
  );
};

export default CtaSection;
