import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Developer",
          "Systems Integrator",
          "Freelancer",
          "Full Stack Developer",
          "Open Source Contributor",
          "Machine Learning Enthusiast",
          "Network & Cyber Systems Architect",
          "Security Innovator",
          "Hobbiest Astrophysicist",
          "AI/Neural Networks & Deep Learning Enthusiast",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
