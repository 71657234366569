import React from 'react';
import { SiMatrix } from "react-icons/si";
import styles from './ManifestoDock.module.css'; // Import the CSS Module

// Component for the floating Hacker Manifesto Dock
// Added onHide prop
const ManifestoDock = ({ onOpenManifesto, onHide, isVisible }) => { 
  
  // If not visible, don't render
  if (!isVisible) {
    return null;
  }

  // Use the onHide prop passed from the parent
  const handleHideClick = (e) => {
    e.stopPropagation(); // Prevent triggering onOpenManifesto
    onHide(); // Call the parent's function to update state
  };

  return (
    // Use module style for the main container
    <div className={styles.manifestoDock}>
      {/* Invisible dismiss area in the top-right corner */}
      <div
        className={styles.dismissArea}
        onClick={handleHideClick}
        aria-label="Close Manifesto Dock" // Keep accessibility label
      ></div>
      <div className={styles.backgroundGradient}></div>
      {/* Use the passed onOpenManifesto prop */}
      <div className={styles.dockContent} onClick={onOpenManifesto}>
        <div className={styles.dockHeader}>
          <span className={styles.dockIcon}><SiMatrix /></span>
          <span className={styles.dockTitle}>THE HACKER MANIFESTO</span>
        </div>
        <div className={styles.dockDescription}>
          The revolutionary 1986 text that defined hacker culture and inspired a generation of digital freedom fighters.
        </div>
        <div className={styles.dockTapToRead}>
          {/* Apply global blink class + module class */}
          <span className={`${styles.blinkCode} blink-code`}>[</span> TAP TO READ <span className={`${styles.blinkCode} blink-code`}>]</span>
        </div>
      </div>
    </div>
  );
};

export default ManifestoDock;
