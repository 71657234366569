// main-site/bio/src/data/arsenalItems/nextcloud.js
import React from 'react';
import { SiNextcloud, SiGoogle, SiMicrosoft } from "react-icons/si"; // Added icons
import { FaBook, FaGithub, FaCloud } from "react-icons/fa"; // Added icons

const nextcloud = {
  id: "nextcloud",
  name: "NextCloud",
  icon: <SiNextcloud />,
  category: "storage",
  description: "Self-hosted productivity platform providing file storage, collaboration, and communication tools",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Google Workspace/Microsoft 365 replacement",
    "Document collaboration and sharing",
    "Calendar, contacts, and file synchronization"
  ],
  // Additional details for expanded view
  detailedDescription: "Nextcloud Hub is an open-source, self-hosted content collaboration platform. It combines the convenience and ease of use of consumer-grade solutions like Google Drive and Dropbox with the security, privacy, and control business needs. Access & sync your files, contacts, calendars & communicate and collaborate across your devices.",
  advantages: [
    "Complete data sovereignty by self-hosting your files and data",
    "Extensible through a rich app ecosystem (Calendar, Contacts, Mail, Talk, Office, etc.)",
    "Strong focus on security and privacy features",
    "Cross-platform clients for desktop and mobile synchronization",
    "Federation allows sharing across different Nextcloud instances"
  ],
  alternatives: [
    { name: "Google Workspace", icon: <SiGoogle />, sovereignty: 1 },
    { name: "Microsoft 365 / OneDrive", icon: <SiMicrosoft />, sovereignty: 1 },
    { name: "Dropbox", icon: <FaCloud />, sovereignty: 1 },
    { name: "ownCloud (Origin of Nextcloud)", icon: <FaCloud />, sovereignty: 4 }
  ],
  resources: [
    {
      title: "Official Website",
      url: "https://nextcloud.com/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository (Server)",
      url: "https://github.com/nextcloud/server",
      icon: <FaGithub />
    },
    {
      title: "App Store",
      url: "https://apps.nextcloud.com/",
      icon: <FaCloud />
    }
  ],
  installationSteps: [
    "Choose an installation method: Docker, Snap, Web Installer, or manual setup",
    "Ensure server meets prerequisites (PHP, database, web server)",
    "Download Nextcloud server code or use the chosen package manager",
    "Configure web server (Nginx or Apache) and database (PostgreSQL, MariaDB/MySQL)",
    "Run the web-based setup wizard or use occ command-line tool",
    "Install recommended apps and configure background jobs (cron)"
  ]
};

export default nextcloud;
