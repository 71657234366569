import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { FaServer, FaShieldAlt, FaLock, FaCode, FaDatabase, FaNetworkWired } from "react-icons/fa";
import { Link } from "react-router-dom";

function Liberation() {
  // Liberation pathways data
  const pathways = [
    {
      id: "infrastructure",
      icon: <FaServer />,
      title: "Infrastructure Liberation",
      description: "Breaking free from corporate cloud dependency through self-hosted, sovereign alternatives.",
      solutions: [
        {
          name: "Proxmox Virtualization",
          description: "Enterprise-grade virtualization that you control completely, with no licensing costs or artificial limitations."
        },
        {
          name: "Self-Hosted Cloud",
          description: "NextCloud, ownCloud, and custom deployments that replace proprietary SaaS with solutions you own."
        },
        {
          name: "Zero Trust Architecture",
          description: "Security architecture that treats all traffic as potentially hostile and requires explicit verification."
        }
      ],
      case: "A mid-sized accounting firm reduced their cloud expenses by 73% while gaining complete data sovereignty by transitioning to a self-hosted infrastructure using Proxmox and NextCloud."
    },
    {
      id: "security",
      icon: <FaShieldAlt />,
      title: "Security Sovereignty",
      description: "Implementing security measures that protect without surrendering control to third parties.",
      solutions: [
        {
          name: "Zero-Knowledge Systems",
          description: "Systems where only you possess the keys, making your data inaccessible even to service providers."
        },
        {
          name: "Post-Quantum Cryptography",
          description: "Forward-looking encryption that will remain secure even in the quantum computing era."
        },
        {
          name: "Secure Communications",
          description: "Matrix, Signal, and other secure channels that keep your conversations private."
        }
      ],
      case: "A human rights organization implemented end-to-end encrypted communications and zero-knowledge backups, protecting sensitive information from both hackers and hostile governments."
    },
    {
      id: "data",
      icon: <FaDatabase />,
      title: "Data Sovereignty",
      description: "Regaining complete control and ownership of your organization's data.",
      solutions: [
        {
          name: "Data Repatriation",
          description: "Methodologies to extract your data from third-party services and bring it back under your control."
        },
        {
          name: "Sovereign Storage Infrastructure",
          description: "S3-compatible storage systems that you control entirely without vendor lock-in."
        },
        {
          name: "Jurisdiction Control",
          description: "Ensuring your data remains in jurisdictions that align with your legal and privacy requirements."
        }
      ],
      case: "A healthcare provider successfully migrated their patient data from a proprietary cloud service to an on-premises solution, ensuring HIPAA compliance and eliminating dependency on a vendor that had repeatedly raised prices."
    },
    {
      id: "network",
      icon: <FaNetworkWired />,
      title: "Network Independence",
      description: "Creating robust, sovereign network infrastructure free from surveillance and throttling.",
      solutions: [
        {
          name: "Mesh Networks",
          description: "Resilient network architectures that eliminate single points of failure and central authorities."
        },
        {
          name: "WireGuard VPN Infrastructure",
          description: "Modern, efficient VPN tunneling that secures your network communications."
        },
        {
          name: "Cross-Border Security",
          description: "Special focus on securing data as it moves between different jurisdictions like Buffalo and Hamilton."
        }
      ],
      case: "A manufacturing company with locations in both the US and Canada implemented a cross-border mesh network that maintained data sovereignty while providing seamless connectivity between facilities."
    },
    {
      id: "custom",
      icon: <FaCode />,
      title: "Custom Development",
      description: "Building software that serves your needs instead of vendor business models.",
      solutions: [
        {
          name: "Bespoke Solutions",
          description: "Custom software development that replaces vendor-controlled SaaS with solutions you own completely."
        },
        {
          name: "Legacy System Liberation",
          description: "Freeing your data and processes from aging, proprietary systems through modern alternatives."
        },
        {
          name: "API Independence",
          description: "Reducing dependency on third-party APIs by internalizing critical functionality."
        }
      ],
      case: "A real estate firm replaced five separate SaaS subscriptions with a single custom platform that they owned outright, saving thousands per month while gaining complete control over features and data."
    },
    {
      id: "knowledge",
      icon: <FaLock />,
      title: "Knowledge Transfer",
      description: "Breaking dependency cycles through training, documentation, and empowerment.",
      solutions: [
        {
          name: "Documentation Systems",
          description: "Comprehensive knowledge bases that capture institutional knowledge and reduce vendor dependence."
        },
        {
          name: "Training Programs",
          description: "Building internal capability to maintain and extend systems without external dependencies."
        },
        {
          name: "Digital Literacy Development",
          description: "Elevating the technical understanding of your entire organization to reduce vulnerability."
        }
      ],
      case: "An educational institution eliminated their dependency on external consultants by implementing a comprehensive knowledge transfer program, resulting in 100% in-house management of their previously outsourced systems."
    }
  ];

  return (
    <section className="liberation-page">
      <Particle />
      <Container fluid className="liberation-section">
        <Container>
          {/* Header */}
          <div className="page-header">
            <h1 className="gradient-text">Liberation <span className="accent-text">Pathways</span></h1>
            <div className="title-decoration">
              <div className="decoration-line"></div>
              <div className="decoration-icon">
                <FaLock />
              </div>
              <div className="decoration-line"></div>
            </div>
            <p className="header-description">
              Strategic approaches to break free from digital dependency and achieve true technological sovereignty.
            </p>
          </div>
          
          {/* Liberation Philosophy */}
          <div className="literary-prose my-5">
            <div className="prose-wrapper">
              <p className="prose-content">
                <span className="prose-dropcap">"L</span>iberation is not a <span className="prose-strikethrough">product</span> <span className="prose-heading">PROCESS</span> that unfolds across multiple domains of technological existence. Each pathway intertwines with others to form a <span className="prose-spaced">c o m p r e h e n s i v e</span> approach to sovereignty. The journey begins with awareness, proceeds through resistance, and culminates in <span className="prose-italic">true ownership</span> of your digital destiny."
              </p>
            </div>
          </div>
          
          {/* Liberation Pathways Grid */}
          <div className="pathways-grid my-5">
            {pathways.map((pathway, index) => (
              <div className="pathway-section" key={pathway.id} id={pathway.id}>
                <div className="pathway-header">
                  <div className="pathway-number">{String(index + 1).padStart(2, '0')}</div>
                  <div className="pathway-icon">{pathway.icon}</div>
                  <h2 className="pathway-title">{pathway.title}</h2>
                </div>
                
                <p className="pathway-description">
                  {pathway.description}
                </p>
                
                <div className="terminal-box">
                  <div className="terminal-header">
                    <div className="terminal-button red"></div>
                    <div className="terminal-button yellow"></div>
                    <div className="terminal-button green"></div>
                    <div className="terminal-title">{pathway.id}_solutions.sh</div>
                  </div>
                  <div className="terminal-body">
                    <div className="highlight-comment"># Liberation solutions for {pathway.title}</div>
                    <div className="terminal-line">
                      <span className="highlight-keyword">function</span> <span className="highlight-function">display_solutions</span>() &#123;
                    </div>
                    
                    {pathway.solutions.map((solution, i) => (
                      <div key={i} className="solution-block">
                        <div className="terminal-line indent">
                          <span className="highlight-keyword">echo</span> <span className="highlight-string">"{solution.name}:"</span>
                        </div>
                        <div className="terminal-line indent-double">
                          <span className="highlight-comment"># {solution.description}</span>
                        </div>
                      </div>
                    ))}
                    
                    <div className="terminal-line">&#125;</div>
                    <div className="terminal-line">
                      display_solutions
                    </div>
                  </div>
                </div>
                
                <div className="case-study">
                  <div className="case-study-header">
                    <h3>Liberation Case Study</h3>
                  </div>
                  <p className="case-study-content">
                    {pathway.case}
                  </p>
                </div>
              </div>
            ))}
          </div>
          
          {/* Liberation Process */}
          <div className="liberation-process my-5">
            <div className="manifesto-section-title">
              <h2><span className="section-number">07</span> The Liberation Process</h2>
            </div>
            
            <Row className="process-steps">
              {[
                {
                  number: "01",
                  title: "Assessment",
                  description: "Comprehensive mapping of technological dependencies and vulnerabilities"
                },
                {
                  number: "02",
                  title: "Architecture",
                  description: "Strategic design of sovereignty-focused alternatives to current systems"
                },
                {
                  number: "03",
                  title: "Implementation",
                  description: "Methodical deployment of solutions with sovereignty as the guiding principle"
                },
                {
                  number: "04",
                  title: "Knowledge Transfer",
                  description: "Comprehensive documentation and training to eliminate consultant dependency"
                },
                {
                  number: "05",
                  title: "Sovereignty Maintenance",
                  description: "Ongoing strategies to preserve and extend independence and ownership"
                }
              ].map((step, index) => (
                <Col md={12} key={index}>
                  <div className="process-step">
                    <div className="step-number">{step.number}</div>
                    <div className="step-content">
                      <h3 className="step-title">{step.title}</h3>
                      <p className="step-description">{step.description}</p>
                    </div>
                    {index < 4 && <div className="step-connector"></div>}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          
          {/* Call to Action */}
          <div className="system-alert my-5">
            <div className="alert-header">
              <div className="alert-title">INITIATE LIBERATION</div>
            </div>
            <div className="alert-content">
              <h3 className="alert-heading">BEGIN YOUR JOURNEY TO DIGITAL SOVEREIGNTY</h3>
              <p className="alert-message">
                Every pathway to liberation begins with a single step. Which dependency will you break first?
              </p>
              <Link to="/arsenal" className="alert-button">
                EXPLORE TECHNICAL ARSENAL
              </Link>
            </div>
            <div className="alert-decoration">
              <div className="alert-line"></div>
              <div className="alert-dot top"></div>
              <div className="alert-dot bottom"></div>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
}

export default Liberation;