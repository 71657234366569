// main-site/bio/src/data/arsenalItems/prometheus.js
import React from 'react';
import { SiPrometheus } from "react-icons/si";
import { FaBook, FaGithub, FaServer } from "react-icons/fa"; // Added icons

const prometheus = {
  id: "prometheus",
  name: "Prometheus",
  icon: <SiPrometheus />,
  category: "infrastructure",
  description: "Open-source monitoring and alerting toolkit designed for reliability and scalability",
  sovereignty: 5,
  expertise: 4,
  useCases: [
    "Infrastructure metrics collection",
    "Alert management and notification",
    "Service health monitoring"
  ],
  // Additional details for expanded view
  detailedDescription: "Prometheus is an open-source systems monitoring and alerting toolkit originally built at SoundCloud. Since its inception in 2012, many companies and organizations have adopted Prometheus, and the project has a very active developer and user community. It collects metrics from configured targets at given intervals, evaluates rule expressions, displays the results, and can trigger alerts if some condition is observed to be true.",
  advantages: [
    "Powerful multi-dimensional data model with time series identified by metric name and key/value pairs",
    "Flexible query language (PromQL) to leverage this dimensionality",
    "No reliance on distributed storage; single server nodes are autonomous",
    "Time series collection happens via a pull model over HTTP",
    "Targets are discovered via service discovery or static configuration"
  ],
  alternatives: [
    { name: "InfluxDB (TICK Stack)", icon: <FaServer />, sovereignty: 4 },
    { name: "Nagios", icon: <FaServer />, sovereignty: 5 },
    { name: "Zabbix", icon: <FaServer />, sovereignty: 5 }, // Corrected sovereignty
    { name: "Datadog", icon: <FaServer />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://prometheus.io/docs/introduction/overview/",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://github.com/prometheus/prometheus",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Download the latest Prometheus release for your platform",
    "Extract the archive and navigate into the directory",
    "Configure prometheus.yml with scrape targets and alerting rules",
    "Run the Prometheus binary: ./prometheus --config.file=prometheus.yml",
    "Access the Prometheus expression browser (default: http://localhost:9090)",
    "Configure Alertmanager for handling alerts (separate component)"
  ]
};

export default prometheus;
