import React from 'react';
// Removed unused Col import
import Tilt from "react-parallax-tilt";
import faceImg from "../../Assets/face.jpg";
import DigitalSignature from "../Dystopian/DigitalSignature";
import { 
  FaGithub, 
  FaLinkedin, 
  FaTwitter, 
  FaEnvelope, 
  FaShieldAlt, 
  FaServer, 
  FaCode
} from "react-icons/fa";
import { SiProtonmail } from "react-icons/si";
import styles from './IdentityCard.module.css'; // Import the CSS Module

// Component for the Identity Card display section
const IdentityCard = ({ currentStatementIndex, sovereigntyStatements }) => { 
  // Note: sovereigntyStatements and currentStatementIndex are passed down for the resistance-container part
  
  // Removed resistanceAnimationStyle as the quote is moving to the terminal

  // The Col wrapper is removed as the parent component now handles the grid column
  return (
    // Use a React Fragment or a div as the root element if needed
    // Applying identityDisplay class to a wrapper div if necessary, or directly if structure allows
    <div className={styles.identityDisplay}> {/* Assuming identityDisplay is meant for the wrapper */}
      {/* Visual guide for Z-pattern flow */}
      <div className={styles.zPatternIndicator}></div>

      {/* Identity Card */}
      <div className={styles.identityCard}> {/* Use module style */}
        <div className={styles.identityHeader}>
          <span className={styles.identityId}>ID:RESISTANCE:03172</span>
          <span className={styles.identityStatus}>ACTIVE</span>
        </div>
        
        <Tilt tiltMaxAngleX={15} tiltMaxAngleY={15} glareEnable={true} glareMaxOpacity={0.3} scale={1.05}>
          <div className={styles.identityImageContainer}>
            <img src={faceImg} alt="Casey Tunturi" className={styles.profileImg} />
            <div className={styles.identityBadge}>
              <FaShieldAlt />
            </div>
          </div>
        </Tilt>
        
        <DigitalSignature 
          signature="DIGITAL_LIBERATOR" 
          size="small" 
          animate={true} 
          // Apply module style if DigitalSignature doesn't handle it internally
          style={{ position: 'absolute', bottom: '10px', right: '10px', transform: 'rotate(-5deg)' }} 
        />
        
        <div className={styles.identityDetails}>
                          
          <h2 className={styles.identityName}>CASEY TUNTURI</h2>
          <div className={styles.identityTitle}>Digital Sovereignty Architect</div>
          
          <div className={styles.identityClassifications}>
            <span className={styles.classificationTag}><FaServer /> Infrastructure</span>
            <span className={styles.classificationTag}><FaShieldAlt /> Security</span>
            <span className={styles.classificationTag}><FaCode /> Development</span>
          </div>
          
          <div className={styles.identityClearance}>
            <div className={styles.clearanceLabel}>EXPERTISE LEVEL</div>
            {/* Combine base and specific class */}
            <div className={`${styles.clearanceLevel} ${styles.high}`}>SPECIALIST</div> 
          </div>
          
          <div className={styles.identitySocial}>
            <a href="https://github.com/Fimeg" target="_blank" rel="noreferrer" className={styles.identitySocialIcon}>
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/casey-tunturi-1b59a0183/" target="_blank" rel="noreferrer" className={styles.identitySocialIcon}>
              <FaLinkedin />
            </a>
            <a href="https://twitter.com/Fimeg" target="_blank" rel="noreferrer" className={styles.identitySocialIcon}>
              <FaTwitter />
            </a>
            <a href="mailto:casey@samaritansolutions.net" target="_blank" rel="noreferrer" className={styles.identitySocialIcon}>
              <FaEnvelope />
            </a>
            <a href="mailto:fimeg@proton.me" target="_blank" rel="noreferrer" className={styles.identitySocialIcon}>
              <SiProtonmail />
            </a>
          </div>
        </div>
      </div>
      
      {/* Removed Z-pattern visual guide */}
      {/* <div className={styles.zPatternGuideLine}></div> */}

      {/* Removed Sovereignty Statement Container */}

      {/* Removed System Integrity Monitor Widget */}

    </div> // Closing the wrapper div
    // Removed closing Col tag
  );
};

export default IdentityCard;
