// main-site/bio/src/data/arsenalItems/proxmox.js
import React from 'react';
import { SiProxmox, SiVmware } from "react-icons/si";
import { FaServer, FaBook, FaGithub } from "react-icons/fa";

const proxmox = {
  id: "proxmox",
  name: "Proxmox VE",
  icon: <SiProxmox />,
  category: "infrastructure",
  description: "Open-source virtualization platform combining KVM hypervisor and LXC containers with comprehensive management interface",
  sovereignty: 5,
  expertise: 5,
  useCases: [
    "Self-hosted cloud infrastructure",
    "Complete replacement for VMware and Hyper-V",
    "Disaster recovery solutions with built-in replication"
  ],
  // Additional details for expanded view
  detailedDescription: "Proxmox Virtual Environment is a complete open-source platform for enterprise virtualization. It tightly integrates KVM hypervisor and LXC containers, software-defined storage and networking functionality on a single platform, providing a complete data center management system.",
  advantages: [
    "Zero vendor lock-in with open-source codebase",
    "Web-based management interface for easy administration",
    "Built-in high availability features including clustering and live migration",
    "Integrated backup and restore functionality",
    "Active community and regular security updates"
  ],
  alternatives: [
    { name: "VMware ESXi", icon: <SiVmware />, sovereignty: 2 },
    { name: "Hyper-V", icon: <FaServer />, sovereignty: 1 }
  ],
  resources: [
    {
      title: "Official Documentation",
      url: "https://pve.proxmox.com/wiki/Main_Page",
      icon: <FaBook />
    },
    {
      title: "GitHub Repository",
      url: "https://git.proxmox.com/",
      icon: <FaGithub />
    }
  ],
  installationSteps: [
    "Download the Proxmox VE ISO installer from the official website",
    "Create a bootable USB drive or CD/DVD",
    "Boot from the installation media and follow the guided setup",
    "Configure network settings and storage during installation",
    "Access the web interface at https://[your-server-ip]:8006"
  ]
};

export default proxmox;
